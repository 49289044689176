import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { ChatService } from '@shared/services/api/chat.service';
import { ComponentService, EventEmitterService } from '../../../../@shared/services/common';

@Component({
    selector: 'app-chat-content',
    templateUrl: './chat-content.component.html'
})
export class ChatContentComponent implements OnInit {
    // Decorator
    @ViewChild('scrollMe') scrollMe: ElementRef;
    @ViewChild('chatMessageInput') private chatMessageInput: ElementRef;
    @ViewChild('messageElement') messageElement: ElementRef;
    scrolltop: number = null;

    // Public
    public languageLocal: any = "";
    public activeChat: Boolean;
    public chats;
    public chatGroup;
    public userProfile;
    public chatMessage = '';
    public newChat;
    public user;
    public file;
    contacts: any[] = [];
    chatName: string;
    isSeenMessage: boolean;
    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     * @param {CoreSidebarService} _coreSidebarService
     */
    constructor(private _chatService: ChatService, private _coreSidebarService: CoreSidebarService, public componentService: ComponentService, public eventEmitterService: EventEmitterService) { }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        let storedLang = localStorage.getItem('localLang');
        // Subscribe to Chat Change
        this._chatService.onChatOpenChange.subscribe(res => {
            this.chatMessage = '';
            this.activeChat = res;
            setTimeout(() => {
                //this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
                this.setFocusedMessageId(this.selectedMessage);
            }, 250);
        });

        // Subscribe to Selected Chat Change
        this._chatService.onChatsChange.subscribe(res => {

            this.chats = res;
            this.chatDifferentSender(this.chats);
            setTimeout(() => {
                this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
            }, 0);
        });

        // Subscribe to Selected Chat User Change
        //this._chatService.onSelectedChatUserChange.subscribe(res => {
        //    this.chatGroup = res;
        //});

        this.userProfile = this._chatService.userProfile;
        this.eventEmitterService.invokeChatName.subscribe(name => {
            this.chatName = name.chatName;
        })
        this.scrollToBottom();
        this._chatService.onSearchTextSelectedChange.subscribe(id => {
            this.selectedMessage = id;
            setTimeout(() => this.selectedMessage = 0, 2000)
        })
        this.eventEmitterService.invokeIsSeenMessageEvent.subscribe((isSeen: boolean) => {
            this.isSeenMessage = isSeen;
        })
        
    }

    selectedMessage = 0;
    setFocusedMessageId(id: number) {
        
        const messageId: string = "message-" + id;
        const messageElement: HTMLElement | null = document.getElementById(messageId);
        if (messageElement) {
            messageElement.scrollIntoView({ behavior: 'auto' });
            messageElement.classList.add('highlight');
            setTimeout(() => {
                messageElement.classList.add('hide');
            }, 2000);
            setTimeout(() => {
                messageElement.classList.remove('highlight');
                messageElement.classList.remove('hide');
            }, 3000);
        }
    }
    scrollToBottom(): void {
        if (typeof this.scrollMe != "undefined") {
            setTimeout(() => {
                this.scrollMe.nativeElement.scrollTop = this.scrollMe?.nativeElement.scrollHeight;
            }, 100);
        }
    }
    getContactPhoto(senderId: number): string {

        const contact = this._chatService.contacts.find(c => c.id == senderId);
        return contact ? contact.photo : '../../../../../assets/img/default-avatar.png';
    }
    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Send Chat Message
     */
    sendChatMessage() {
        
        if (this.chatMessage.trim() !== '') {
            const chat = this.chatMessage
            const file = this.file;
            this.chatMessage = '';
            this.file = null

            this._chatService.sendChatMessage(chat, file).then(data => {
                setTimeout(() => {
                    this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
                }, 0);
            })
            const button = document.querySelector('.send');
            button.classList.add('clicked');

            setTimeout(() => {
                button.classList.remove('clicked');
            }, 0);
            const formSendMessageElement = document.querySelector('.form-send-message') as HTMLDivElement;
            formSendMessageElement.classList.remove('error');
        } else {
            const formSendMessageElement = document.querySelector('.form-send-message') as HTMLDivElement;
            formSendMessageElement.classList.add('error');
            setTimeout(() => {
                formSendMessageElement.classList.remove('error');
            }, 1500);
        }

    }

    /**
     * Toggle Sidebar Event
     *
     * @param name
     */
    toggleSidebar(name) {
        this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    }


    /**
     * OnFileSelect Event
     * Gets file info from event
     * @param event
     */
    fileSelect($event) {
        this.file = null
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.file = fileList[0]
        }
    }
    getAudioUrl(fileId: number) {

    }
    downloadLoading = false;
    chatMessageFileDownload(_id) {
        var a: any = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        this.downloadLoading = true;
        this._chatService.chatMessageFileDownload(_id).subscribe((result: any) => {
            if (result) {
                var src = "data:" + result.type + ";base64," + result.fileData;

                const blob = this.base64ToBlob(result.fileData, result.type);
                const fileURL = window.URL.createObjectURL(blob);

                a.href = fileURL;
                a.download = result.fileName;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.downloadLoading = false;
            }
        });
    }
    base64ToBlob(base64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }
    allEmoji = ["&#128512;", "&#128513;", "&#128514;", "&#128516;", "&#128517;", "&#128518;", "&#128519;", "&#128520;", "&#128521;", "&#128522;", "&#128523;", "&#128524;", "&#128525;", "&#128526;", "&#128527;",
        "&#128528;", "&#128529;", "&#128530;", "&#128531;", "&#128533;", "&#128536;", "&#128544;", "&#128546;", "&#128548;", "&#128555;"];
    emojiChatPopoverOpen = false;
    toggleWithEmojiChat() {
        this.emojiChatPopoverOpen = !this.emojiChatPopoverOpen;
    }
    ani() {
        document.getElementById('plane').className = 'animation';
    }
    anitwo() {
        document.getElementById('bg').className = 'animation2';
    }
    createBlob(file) {
        return new Promise(resolve => {
            fetch(file.src)
                .then(res => res.blob())
                .then(blob => { resolve(blob) })
                .catch(err => { console.log(err) })
        });
    }

    clearFile() {
        this.file = null;
    }
    chatMessageSetEmoji(_emoji) {
        let myField = this.chatMessageInput.nativeElement;
        if (!(this.chatMessageInputStartPos)) {
            this.chatMessageInputStartPos = myField.selectionStart;
            this.chatMessageInputEndPos = myField.selectionEnd;
        }
        let myValueClear = this.insertAtCursor(_emoji);
        this.chatMessage = myValueClear;
    }
    insertAtCursor(myValue) {
        ////IE support
        //if (document.selection) {
        //    myField.focus();
        //    sel = document.selection.createRange();
        //    sel.text = myValue;
        //}
        ////MOZILLA and others
        //else
        //: HTMLElement 
        var txtValueEl = document.createElement('textarea');
        txtValueEl.innerHTML = myValue;

        let myField = this.chatMessageInput.nativeElement;
        if (this.chatMessageInputStartPos || this.chatMessageInputStartPos == '0') {
            myField.value = myField.value.substring(0, this.chatMessageInputStartPos)
                + txtValueEl.value
                + myField.value.substring(this.chatMessageInputEndPos, myField.value.length);
        } else {
            myField.value += txtValueEl.value;
        }
        return myField.value;
    }
    hideEmojiPopoper(e) {
        //this.chatMessageInputStartPos = null;
        //this.chatMessageInputEndPos = null;
        let myField = this.chatMessageInput.nativeElement;
        if (!(this.chatMessageInputStartPos)) {
            myField.selectionStart = this.chatMessageInputStartPos;
            myField.selectionEnd = this.chatMessageInputEndPos;
        }
        myField.focus();
    }
    chatMessageInputStartPos = null;
    chatMessageInputEndPos = null;
    chatMessageFocus() {
        let _thisPage = this;
        setTimeout(function () {
            let myField = _thisPage.chatMessageInput.nativeElement;
            _thisPage.chatMessageInputStartPos = myField.selectionStart;
            _thisPage.chatMessageInputEndPos = myField.selectionEnd;
        }, 500);

    }
    chatMessageBlur() {

    }

    chatDifferentSender(chats) {
        if (chats.length > 0) {
            chats[0].isDifferentSender = true;
        }

        for (let i = 1; i < chats.length; i++) {
            chats[i].isDifferentSender = chats[i].sender_id !== chats[i - 1].sender_id;
        }
    }
}
