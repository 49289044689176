import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
    {
        id: 'analytics',
        title: 'Analytics',
        type: 'item',
        icon: 'activity',
        url: 'app/analytics',
        children: [],
        isPages: false,
        isProcess: false,
        isMenu: false,
        isReport: false,
        refresh: false,
        exactMatch: false
    },
    {
        id: 'river',
        title: 'River',
        type: 'section',
        icon: 'activity',
        children: [],
        isPages: false,
        isProcess: false,
        isMenu: false,
        isReport: false,
        refresh: false,
        exactMatch: false
    },
    // {
    //     id: 'riverMenu',
    //     title: 'Katalog',
    //     type: 'section',
    //     icon: 'layers',
    //     children: [],
    //     isPages: false,
    //     isProcess: false,
    //     isMenu: false,
    //     isReport: false,
    //     refresh: false,
    //     exactMatch: false
    // },
    {
        id: 'riverReport',
        title: 'River BI',
        type: 'section',
        icon: 'file-text',
        children: [],
        isPages: false,
        isProcess: false,
        isMenu: false,
        isReport: false,
        refresh: false,
        exactMatch: false
    },
    {
        id: 'riverPages',
        title: 'River Panolar',
        type: 'section',
        icon: 'trello',
        children: [],
        isPages: false,
        isProcess: false,
        isMenu: false,
        isReport: false,
        refresh: false,
        exactMatch: false
    }
]
