import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';

import { Router, ActivatedRoute } from '@angular/router';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { locale, loadMessages } from 'devextreme/localization';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { EventEmitterService } from '@shared/services/common/event-emitter.service';
import { AuthService } from '@shared/services/api/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'app/auth/data/en'
import { locale as french } from 'app/auth/data/fr';
import { locale as german } from 'app/auth/data/de';
import { locale as turkish } from 'app/auth/data/tr';
import { locale as arabic } from 'app/auth/data/ar';
import { locale as azerbaijani } from 'app/auth/data/az';
import { locale as georgian } from 'app/auth/data/geo';
import { locale as dutch } from 'app/auth/data/nl';
import { locale as italian } from 'app/auth/data/it';
import { locale as russian } from 'app/auth/data/ru';

import { CoreTranslationService } from '../@core/services/translation.service';
import { GenericService } from '../@shared/services/api';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

    public isThemeLayout = false;
    public isEmptyLayout = true;

    constructor(
        public _coreConfigService: CoreConfigService,
        private _routerServcie: Router,
        private _activatedRoute: ActivatedRoute,
        private _coreLoadingScreenService: CoreLoadingScreenService,
        private _appInfoService: AppInfoService,
        private _authService: AuthService,
        public domSanitizer: DomSanitizer, 
        private eventEmitterService: EventEmitterService,
        private _coreTranslationService: CoreTranslationService,
        public _translateService: TranslateService,
        private _genericService: GenericService
    ) {
        // Get And Set Translate JSONS
        this._coreTranslationService.translate(english, french, german, turkish, arabic, azerbaijani, georgian, dutch, italian,russian);
        // Set Default Language
        
        this._translateService.setDefaultLang('TRTR');
        // Set Lang Parameter To Local Storage
        this.checkAndSetBrowsersDefaultLangToLocalStorage();
        let upperLang = localStorage.getItem('localLang');
        if (upperLang) {
            upperLang = upperLang.toLowerCase().substring(0, 2);
            locale(upperLang);
        }
        // Burasının asla destroy olduğunu düşünmediğim için Subscription eklemiyorum. Gereksiz tetiklenme oluyorsa kontrol eklenebilir.
        this._translateService.onLangChange.subscribe(langSource => {
            
            if (langSource.lang == "ARAR") {
                var body = document.getElementById('river-body')
                body.classList.add("direction-rtl")
            } else {
                var body = document.getElementById('river-body')
                body.classList.remove("direction-rtl")   
            }

            
            this._genericService.getlogininit(langSource.lang, "dictionary").subscribe((result: any) => {
                this._translateService.setTranslation(result.languages.languageCode, result.languages.langSource, true);
                this._translateService.use(langSource.lang);
            });

            
            localStorage.setItem('localLang', langSource.lang);
            this._translateService.currentLang = langSource.lang
            this._translateService.use(langSource.lang)
            let upperLang = langSource.lang;
            if (upperLang) {
                upperLang = upperLang.toLowerCase().substring(0, 2);
                locale(upperLang);
            }


        })
        


    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    public isLoggedIn = false;
    frontPanelLoading = false;
    frontPanelLoadingText = "";
    isActiveFrontPanel = false;

    logoLogin = null;
    
    /**
     * On init
     */
    ngOnInit(): void {
        this._authService._isLoggedIn.subscribe(val => {
            this.isLoggedIn = val;
        });
        this.eventEmitterService.invokeTaskLinkFrontPanelFunction.subscribe(sub => {
            
            if (sub.data.type == "layout") {
                this.frontPanelLoading = sub.data.loading;
                this.isActiveFrontPanel = sub.data.isActive;
                this.frontPanelLoadingText = sub.data.loadingText;

                this.setBackgroundPanel();
            }
        });
        this.initMessages();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {

    }

    setBackgroundPanel() {
        try {
            let _logoLogin = localStorage.getItem("loginLogoRiver")
            this.logoLogin = this.domSanitizer.bypassSecurityTrustUrl(_logoLogin ? _logoLogin.replace('"', '').replace('"', '') : "");

            let _this = this;
            var taskLinkFrontPanelSetBGInterval = setInterval(function () {
                let _loginBGRiver = localStorage.getItem("loginBGRiver");
                if (_loginBGRiver) {
                    
                    clearInterval(taskLinkFrontPanelSetBGInterval);

                    var $page = document.getElementsByClassName("full-page")[0];

                    var image_src = Math.floor((Math.random() * 4) + 1);
                    var image_container = document.createElement("div");
                    image_container.classList.add("full-page-background");
                    if (typeof _loginBGRiver != "undefined" && _loginBGRiver != null && _loginBGRiver != "") {
                        image_container.style.backgroundImage = "url(" + _loginBGRiver + ")";
                    } else {
                        image_container.style.backgroundImage = "url(assets/img/bg/bg" + image_src + ".jpg)";
                    }
                    $page.appendChild(image_container);
                    $page.classList.add("login-page");

                }

            }, 250);
            setTimeout(function () {
                clearInterval(taskLinkFrontPanelSetBGInterval);
            }, 10000);
            //if (_loginBGRiver) { 

            //}
        } catch (ex) { }
    }

    checkAndSetBrowsersDefaultLangToLocalStorage() {

        var userLang = navigator.language;

        var localLang = localStorage.getItem('localLang');

        if (typeof localLang == "undefined" || localLang == null) {

            var localSelectLang = this._coreTranslationService.countryFlags.find(f => f.Key == userLang)
            if (typeof localSelectLang != "undefined") {
                localLang = localSelectLang.Code;
            } else {
                localLang = this._translateService.defaultLang;  // Default dil ataması TODO: Serviceden veya app.config dosyasından alınacak.
            }

            localStorage.setItem('localLang', localLang);

        }

        
        this._translateService.currentLang = localLang

        // Uygulamanın Default dilini burada TRTR olarak güncelliyoruz. 
        // Eğer LocalStorage'da dil yoksa Default Tarayıcı dilinin alınması gerekiyor.
        
        this._genericService.getlogininit(localLang, "dictionary").subscribe((result: any) => {
            this._translateService.setTranslation(result.languages.languageCode, result.languages.langSource, true);
            this._translateService.use(localLang);

            if (localLang == "ARAR") {
                var body = document.getElementById('river-body')
                body.classList.add("direction-rtl")
            } else {
                var body = document.getElementById('river-body')
                body.classList.remove("direction-rtl")
            }

        });
    }

    initMessages() {
        //loadMessages(deMessages);
        //loadMessages(ruMessages);
        loadMessages({
            'en': {
                "dxList-selectAll": "Select All",
                "dxDataGrid-ariaSelectAll": "Select All",
                Yes: "Yes",
                No: "No",
                Cancel: "Cancel",
                Clear: "Clear",
                Done: "Done",
                Loading: "Loading...",
                Select: "Select...",
                Search: "Search",
                Back: "Back",
                OK: "OK",
                "dxPager-infoText": "Page {0} of {1} ({2} items)",
                "dxDataGrid-headerFilterOK": "OK",
                "dxDataGrid-headerFilterCancel": "Cancel",

                "dxDataGrid-editingEditRow": "Edit",
                "dxDataGrid-filterBuilderPopupTitle": "Filter Builder",
                "dxDataGrid-filterPanelCreateFilter": "Create Filter",
                "dxDataGrid-filterPanelClearFilter": "Clear",
                "dxDataGrid-filterPanelFilterEnabledHint": "Enable the filter",
                "dxDataGrid-editingSaveRowChanges": "Save",
                "dxDataGrid-editingCancelRowChanges": "Cancel",
                "dxDataGrid-editingDeleteRow": "Delete",
                "dxDataGrid-editingUndeleteRow": "Undelete",
                "dxDataGrid-editingConfirmDeleteMessage": "Are you sure you want to delete this record?",
                "dxDataGrid-validationCancelChanges": "Cancel changes",
                "dxDiagram-dialogButtonOK": "OK",
                "dxDiagram-dialogButtonCancel": "Cancel",
                "dxDataGrid-exportTo": "Export",
                "dxDataGrid-exportToExcel": "Export to Excel file",
                "dxDataGrid-exporting": "Exporting...",
                "dxDataGrid-excelFormat": "Excel file",
                "dxDataGrid-selectedRows": "Selected rows",
                "dxDataGrid-exportSelectedRows": "Export selected rows",
                "dxDataGrid-exportAll": "Export all data",
                "dxDataGrid-searchPanelPlaceholder": "Search...",
                "dxDataGrid-noDataText": "No data",
                "dxDataGrid-headerFilterEmptyValue": "(Blanks)",
                "dxDataGrid-filterRowShowAllText": "(All)",


                "dxScheduler-editorLabelTitle": "Subject",
                "dxScheduler-editorLabelStartDate": "Start Date",
                "dxScheduler-editorLabelEndDate": "End Date",
                "dxScheduler-editorLabelDescription": "Description",
                "dxScheduler-editorLabelRecurrence": "Repeat",
                "dxScheduler-openAppointment": "Open appointment",
                "dxScheduler-recurrenceNever": "Never",
                "dxScheduler-recurrenceMinutely": "Every minute",
                "dxScheduler-recurrenceHourly": "Hourly",
                "dxScheduler-recurrenceDaily": "Daily",
                "dxScheduler-recurrenceWeekly": "Weekly",
                "dxScheduler-recurrenceMonthly": "Monthly",
                "dxScheduler-recurrenceYearly": "Yearly",
                "dxScheduler-recurrenceRepeatEvery": "Repeat Every",
                "dxScheduler-recurrenceRepeatOn": "Repeat On",
                "dxScheduler-recurrenceEnd": "End repeat",
                "dxScheduler-recurrenceAfter": "After",
                "dxScheduler-recurrenceOn": "On",
                "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
                "dxScheduler-recurrenceRepeatHourly": "hour(s)",
                "dxScheduler-recurrenceRepeatDaily": "day(s)",
                "dxScheduler-recurrenceRepeatWeekly": "week(s)",
                "dxScheduler-recurrenceRepeatMonthly": "month(s)",
                "dxScheduler-recurrenceRepeatYearly": "year(s)",
                "dxScheduler-switcherDay": "Day",
                "dxScheduler-switcherWeek": "Week",
                "dxScheduler-switcherWorkWeek": "Work Week",
                "dxScheduler-switcherMonth": "Month",
                "dxScheduler-switcherAgenda": "Agenda",
                "dxScheduler-switcherTimelineDay": "Timeline Day",
                "dxScheduler-switcherTimelineWeek": "Timeline Week",
                "dxScheduler-switcherTimelineWorkWeek": "Timeline Work Week",
                "dxScheduler-switcherTimelineMonth": "Timeline Month",
                "dxScheduler-recurrenceRepeatOnDate": "on date",
                "dxScheduler-recurrenceRepeatCount": "occurrence(s)",
                "dxScheduler-allDay": "All day",
                "dxScheduler-confirmRecurrenceEditMessage": "Do you want to edit only this appointment or the whole series?",
                "dxScheduler-confirmRecurrenceDeleteMessage": "Do you want to delete only this appointment or the whole series?",
                "dxScheduler-confirmRecurrenceEditSeries": "Edit series",
                "dxScheduler-confirmRecurrenceDeleteSeries": "Delete series",
                "dxScheduler-confirmRecurrenceEditOccurrence": "Edit appointment",
                "dxScheduler-confirmRecurrenceDeleteOccurrence": "Delete appointment",
                "dxScheduler-noTimezoneTitle": "No timezone",
                "dxScheduler-moreAppointments": "{0} more",

            },
            'tr': {
                "dxList-selectAll": "Tümünü Seç",
                "dxDataGrid-ariaSelectAll": "Tümünü Seç",
                Yes: "Evet",
                No: "Hayır",
                Cancel: "İptal",
                Clear: "Temizle",
                Done: "Tamam",
                Loading: "Yükleniyor...",
                Select: "Seç...",
                Search: "Ara",
                Back: "Geri",
                OK: "Tamam",
                "dxPager-infoText": "Toplam {1} sayfadan {0}. gösteriliyor ({2} kayıt)",
                "dxDataGrid-headerFilterOK": "Tamam",
                "dxDataGrid-headerFilterCancel": "İptal",
                "dxDataGrid-editingEditRow": "Düzenle",
                "dxDataGrid-editingSaveRowChanges": "Kaydet",
                "dxDataGrid-editingCancelRowChanges": "İptal",
                "dxDataGrid-editingDeleteRow": "Sil",
                "dxDataGrid-filterBuilderPopupTitle": "Filtre Oluşturucu",
                "dxDataGrid-filterPanelCreateFilter": "Filtre Oluştur",
                "dxDataGrid-filterPanelClearFilter": "Temizle",
                "dxDataGrid-filterPanelFilterEnabledHint": "Filtreyi etkinleştir",
                "dxDataGrid-editingUndeleteRow": "Silmeyi geri al",
                "dxDataGrid-editingConfirmDeleteMessage": "Bu kaydı silmek istediğinize emin misiniz?",
                "dxDataGrid-validationCancelChanges": "Değişiklikleri İptal Et",
                "dxDiagram-dialogButtonOK": "Tamam",
                "dxDiagram-dialogButtonCancel": "İptal",
                "dxDataGrid-exportTo": "Dışa Aktar",
                "dxDataGrid-exportToExcel": "Excel Dosyasını Dışa Aktar",
                "dxDataGrid-exporting": "Dışa Aktarılıyor...",
                "dxDataGrid-excelFormat": "Excel Dosyası",
                "dxDataGrid-selectedRows": "Seçili Kolonlar",
                "dxDataGrid-exportSelectedRows": "Seçili Kolonları Dışa Aktar",
                "dxDataGrid-exportAll": "Bütün Kayıtları Dışa Aktar",
                "dxDataGrid-searchPanelPlaceholder": "Ara...",
                "dxDataGrid-noDataText": "Veri Bulunamadı",
                "dxDataGrid-headerFilterEmptyValue": "(Boş Veri)",
                "dxDataGrid-filterRowShowAllText": "(Tümü)",

                "dxScheduler-editorLabelTitle": "Konu",
                "dxScheduler-editorLabelStartDate": "Başlangıç Tarihi",
                "dxScheduler-editorLabelEndDate": "Bitiş Tarihi",
                "dxScheduler-editorLabelDescription": "Açıklama",
                "dxScheduler-editorLabelRecurrence": "Tekrar",
                "dxScheduler-openAppointment": "Randevu Aç",
                "dxScheduler-recurrenceNever": "Asla",
                "dxScheduler-recurrenceMinutely": "Minutely",
                "dxScheduler-recurrenceHourly": "Hourly",
                "dxScheduler-recurrenceDaily": "Günlük",
                "dxScheduler-recurrenceWeekly": "Haftalık",
                "dxScheduler-recurrenceMonthly": "Aylık",
                "dxScheduler-recurrenceYearly": "Yıllık",
                "dxScheduler-recurrenceRepeatEvery": "Her tekrarla",
                "dxScheduler-recurrenceRepeatOn": "Tekrarla",
                "dxScheduler-recurrenceEnd": "Tekrarı bitir",
                "dxScheduler-recurrenceAfter": "Sonra",
                "dxScheduler-recurrenceOn": "İle",
                "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
                "dxScheduler-recurrenceRepeatHourly": "hour(s)",
                "dxScheduler-recurrenceRepeatDaily": "günler",
                "dxScheduler-recurrenceRepeatWeekly": "haftalar",
                "dxScheduler-recurrenceRepeatMonthly": "aylar",
                "dxScheduler-recurrenceRepeatYearly": "yıllar",
                "dxScheduler-switcherDay": "Gün",
                "dxScheduler-switcherWeek": "Hafta",
                "dxScheduler-switcherWorkWeek": "Çalışma Haftası",
                "dxScheduler-switcherMonth": "Ay",
                "dxScheduler-switcherAgenda": "Ajanda",
                "dxScheduler-switcherTimelineDay": "Zaman Çizelgesi Günü",
                "dxScheduler-switcherTimelineWeek": "Zaman Çizelgesi Haftası",
                "dxScheduler-switcherTimelineWorkWeek": "Zaman Çizelgesi Çalışma Haftası",
                "dxScheduler-switcherTimelineMonth": "TZaman Çizelgesi Çalışma Ayı",
                "dxScheduler-recurrenceRepeatOnDate": "tarihinde",
                "dxScheduler-recurrenceRepeatCount": "olaylar",
                "dxScheduler-allDay": "Tüm gün",
                "dxScheduler-confirmRecurrenceEditMessage": "Yalnızca bu randevuyu veya tüm diziyi düzenlemek ister misiniz?",
                "dxScheduler-confirmRecurrenceDeleteMessage": "Yalnızca bu randevuyu veya tüm diziyi silmek istiyor musunuz?",
                "dxScheduler-confirmRecurrenceEditSeries": "Serileri düzenle",
                "dxScheduler-confirmRecurrenceDeleteSeries": "Serileri sil",
                "dxScheduler-confirmRecurrenceEditOccurrence": "Randevuyu düzenle",
                "dxScheduler-confirmRecurrenceDeleteOccurrence": "Randevuyu sil",
                "dxScheduler-noTimezoneTitle": "Saat dilimi yok",
                "dxScheduler-moreAppointments": "{0} daha",
            },
            "ar": {
                "dxList-selectAll": "حدد الكل",
                "dxDataGrid-ariaSelectAll": "حدد الكل",
                "Yes": "نعم",
                "No": "لا",
                "Cancel": "إلغاء",
                "Clear": "مسح",
                "Done": "تم",
                "Loading": "جاري التحميل...",
                "Select": "اختر...",
                "Search": "بحث",
                "Back": "رجوع",
                "OK": "موافق",
                "dxPager-infoText": "عرض {0} من {1} ({2} عنصر)",
                "dxDataGrid-headerFilterOK": "موافق",
                "dxDataGrid-headerFilterCancel": "إلغاء",
                "dxDataGrid-editingEditRow": "تحرير",
                "dxDataGrid-editingSaveRowChanges": "حفظ",
                "dxDataGrid-editingCancelRowChanges": "إلغاء",
                "dxDataGrid-editingDeleteRow": "حذف",
                "dxDataGrid-filterBuilderPopupTitle": "مُنشئ الفلتر",
                "dxDataGrid-filterPanelCreateFilter": "إنشاء فلتر",
                "dxDataGrid-filterPanelClearFilter": "مسح",
                "dxDataGrid-filterPanelFilterEnabledHint": "تفعيل الفلتر",
                "dxDataGrid-editingUndeleteRow": "تراجع عن الحذف",
                "dxDataGrid-editingConfirmDeleteMessage": "هل أنت متأكد من رغبتك في حذف هذا السجل؟",
                "dxDataGrid-validationCancelChanges": "إلغاء التغييرات",
                "dxDiagram-dialogButtonOK": "موافق",
                "dxDiagram-dialogButtonCancel": "إلغاء",
                "dxDataGrid-exportTo": "تصدير إلى",
                "dxDataGrid-exportToExcel": "تصدير إلى Excel",
                "dxDataGrid-exporting": "جاري التصدير...",
                "dxDataGrid-excelFormat": "ملف Excel",
                "dxDataGrid-selectedRows": "الصفوف المحددة",
                "dxDataGrid-exportSelectedRows": "تصدير الصفوف المحددة",
                "dxDataGrid-exportAll": "تصدير كافة السجلات",
                "dxDataGrid-searchPanelPlaceholder": "بحث...",
                "dxDataGrid-noDataText": "لا توجد بيانات",
                "dxDataGrid-headerFilterEmptyValue": "(قيمة فارغة)",
                "dxDataGrid-filterRowShowAllText": "(الكل)",
                "dxScheduler-editorLabelTitle": "العنوان",
                "dxScheduler-editorLabelStartDate": "تاريخ البدء",
                "dxScheduler-editorLabelEndDate": "تاريخ الانتهاء",
                "dxScheduler-editorLabelDescription": "الوصف",
                "dxScheduler-editorLabelRecurrence": "تكرار",
                "dxScheduler-openAppointment": "فتح الموعد",
                "dxScheduler-recurrenceNever": "أبدًا",
                "dxScheduler-recurrenceMinutely": "كل دقيقة",
                "dxScheduler-recurrenceHourly": "كل ساعة",
                "dxScheduler-recurrenceDaily": "يوميًا",
                "dxScheduler-recurrenceWeekly": "أسبوعيًا",
                "dxScheduler-recurrenceMonthly": "شهريًا",
                "dxScheduler-recurrenceYearly": "سنويًا",
                "dxScheduler-recurrenceRepeatEvery": "تكرار كل",
                "dxScheduler-recurrenceRepeatOn": "تكرار في",
                "dxScheduler-recurrenceEnd": "نهاية التكرار",
                "dxScheduler-recurrenceAfter": "بعد",
                "dxScheduler-recurrenceOn": "في",
                "dxScheduler-recurrenceRepeatMinutely": "دقيقة(دقائق)",
                "dxScheduler-recurrenceRepeatHourly": "ساعة(ساعات)",
                "dxScheduler-recurrenceRepeatDaily": "يوم(أيام)",
                "dxScheduler-recurrenceRepeatWeekly": "أسبوع(أسابيع)",
                "dxScheduler-recurrenceRepeatMonthly": "شهر(شهور)",
                "dxScheduler-recurrenceRepeatYearly": "سنة(سنوات)",
                "dxScheduler-switcherDay": "يوم",
                "dxScheduler-switcherWeek": "أسبوع",
                "dxScheduler-switcherWorkWeek": "أيام العمل",
                "dxScheduler-switcherMonth": "شهر",
                "dxScheduler-switcherAgenda": "جدول",
                "dxScheduler-switcherTimelineDay": "الجدول الزمني لليوم",
                "dxScheduler-switcherTimelineWeek": "الجدول الزمني للأسبوع",
                "dxScheduler-switcherTimelineWorkWeek": "الجدول الزمني لأيام العمل",
                "dxScheduler-switcherTimelineMonth": "الجدول الزمني للشهر",
                "dxScheduler-recurrenceRepeatOnDate": "في التاريخ",
                "dxScheduler-recurrenceRepeatCount": "الأحداث",
                "dxScheduler-allDay": "طوال اليوم",
                "dxScheduler-confirmRecurrenceEditMessage": "هل ترغب فقط في تحرير هذا الموعد أم جميع الأحداث في السلسلة؟",
                "dxScheduler-confirmRecurrenceDeleteMessage": "هل ترغب فقط في حذف هذا الموعد أم جميع الأحداث في السلسلة؟",
                "dxScheduler-confirmRecurrenceEditSeries": "تحرير السلسلة",
                "dxScheduler-confirmRecurrenceDeleteSeries": "حذف السلسلة",
                "dxScheduler-confirmRecurrenceEditOccurrence": "تحرير الموعد",
                "dxScheduler-confirmRecurrenceDeleteOccurrence": "حذف الموعد",
                "dxScheduler-noTimezoneTitle": "لا توجد منطقة زمنية",
                "dxScheduler-moreAppointments": "المزيد من المواعيد: {0}"
            },
            "az": {
                "dxList-selectAll": "Hamısını seç",
                "dxDataGrid-ariaSelectAll": "Hamısını seç",
                "Yes": "Bəli",
                "No": "Xeyr",
                "Cancel": "Ləğv et",
                "Clear": "Təmizlə",
                "Done": "Qurtardı",
                "Loading": "Yüklənir...",
                "Select": "Seç...",
                "Search": "Axtar",
                "Back": "Geri",
                "OK": "OK",
                "dxPager-infoText": "Cəmi {1} səhifədən {0}. göstərilir ({2} qeyd)",
                "dxDataGrid-headerFilterOK": "OK",
                "dxDataGrid-headerFilterCancel": "Ləğv et",
                "dxDataGrid-editingEditRow": "Redaktə et",
                "dxDataGrid-editingSaveRowChanges": "Saxla",
                "dxDataGrid-editingCancelRowChanges": "Ləğv et",
                "dxDataGrid-editingDeleteRow": "Sil",
                "dxDataGrid-filterBuilderPopupTitle": "Filtr Oluşdurucusu",
                "dxDataGrid-filterPanelCreateFilter": "Filtr Oluşdur",
                "dxDataGrid-filterPanelClearFilter": "Təmizlə",
                "dxDataGrid-filterPanelFilterEnabledHint": "Filtrə icazə ver",
                "dxDataGrid-editingUndeleteRow": "Silinməyi geri qaytar",
                "dxDataGrid-editingConfirmDeleteMessage": "Bu qeydi silmək istədiyinizə əminsiniz?",
                "dxDataGrid-validationCancelChanges": "Dəyişiklikləri ləğv edin",
                "dxDiagram-dialogButtonOK": "OK",
                "dxDiagram-dialogButtonCancel": "Ləğv et",
                "dxDataGrid-exportTo": "İxrac et",
                "dxDataGrid-exportToExcel": "Excel faylına İxrac et",
                "dxDataGrid-exporting": "İxrac edilir...",
                "dxDataGrid-excelFormat": "Excel faylı",
                "dxDataGrid-selectedRows": "Seçilmiş sətirlər",
                "dxDataGrid-exportSelectedRows": "Seçilmiş sətirləri İxrac et",
                "dxDataGrid-exportAll": "Bütün qeydləri İxrac et",
                "dxDataGrid-searchPanelPlaceholder": "Axtar...",
                "dxDataGrid-noDataText": "Məlumat tapılmadı",
                "dxDataGrid-headerFilterEmptyValue": "(Boş məlumat)",
                "dxDataGrid-filterRowShowAllText": "(Hamısı)",
                "dxScheduler-editorLabelTitle": "Başlıq",
                "dxScheduler-editorLabelStartDate": "Başlanğıc tarixi",
                "dxScheduler-editorLabelEndDate": "Bitiş tarixi",
                "dxScheduler-editorLabelDescription": "Təsvir",
                "dxScheduler-editorLabelRecurrence": "Təkrar",
                "dxScheduler-openAppointment": "Talqın aç",
                "dxScheduler-recurrenceNever": "Hər hansısa",
                "dxScheduler-recurrenceMinutely": "Dəqiqə",
                "dxScheduler-recurrenceHourly": "Saat",
                "dxScheduler-recurrenceDaily": "Günlük",
                "dxScheduler-recurrenceWeekly": "Həftəlik",
                "dxScheduler-recurrenceMonthly": "Aylıq",
                "dxScheduler-recurrenceYearly": "İllik",
                "dxScheduler-recurrenceRepeatEvery": "Hər",
                "dxScheduler-recurrenceRepeatOn": "Üzrə",
                "dxScheduler-recurrenceEnd": "Bitmək",
                "dxScheduler-recurrenceAfter": "Sonra",
                "dxScheduler-recurrenceOn": "Üzrə",
                "dxScheduler-recurrenceRepeatMinutely": "dəqiqə(dəqiqələr)",
                "dxScheduler-recurrenceRepeatHourly": "saat(saətlər)",
                "dxScheduler-recurrenceRepeatDaily": "gün(gün)",
                "dxScheduler-recurrenceRepeatWeekly": "həftə(həftələr)",
                "dxScheduler-recurrenceRepeatMonthly": "ay(aylar)",
                "dxScheduler-recurrenceRepeatYearly": "il(il)",
                "dxScheduler-switcherDay": "Gün",
                "dxScheduler-switcherWeek": "Həftə",
                "dxScheduler-switcherWorkWeek": "İş həftəsi",
                "dxScheduler-switcherMonth": "Ay",
                "dxScheduler-switcherAgenda": "Tədbir",
                "dxScheduler-switcherTimelineDay": "Vaxt xəritəsi günü",
                "dxScheduler-switcherTimelineWeek": "Vaxt xəritəsi həftəsi",
                "dxScheduler-switcherTimelineWorkWeek": "İş günü vaxt xəritəsi",
                "dxScheduler-switcherTimelineMonth": "İş ayı vaxt xəritəsi",
                "dxScheduler-recurrenceRepeatOnDate": "tarixində",
                "dxScheduler-recurrenceRepeatCount": "hadisələr",
                "dxScheduler-allDay": "Bütün gün",
                "dxScheduler-confirmRecurrenceEditMessage": "Yalnız bu tədbiri redaktə etmək istəyirsinizmi, yoxsa bütün seriyaları?",
                "dxScheduler-confirmRecurrenceDeleteMessage": "Yalnız bu tədbiri silmək istəyirsinizmi, yoxsa bütün seriyaları?",
                "dxScheduler-confirmRecurrenceEditSeries": "Seriyanı redaktə et",
                "dxScheduler-confirmRecurrenceDeleteSeries": "Seriyanı sil",
                "dxScheduler-confirmRecurrenceEditOccurrence": "Tədbiri redaktə et",
                "dxScheduler-confirmRecurrenceDeleteOccurrence": "Tədbiri sil",
                "dxScheduler-noTimezoneTitle": "Vaxt zonası yoxdur",
                "dxScheduler-moreAppointments": "Daha çox: {0}"
            },
            "de": {
                "dxList-selectAll": "Alle auswählen",
                "dxDataGrid-ariaSelectAll": "Alle auswählen",
                "Yes": "Ja",
                "No": "Nein",
                "Cancel": "Abbrechen",
                "Clear": "Löschen",
                "Done": "Fertig",
                "Loading": "Lädt...",
                "Select": "Auswählen...",
                "Search": "Suchen",
                "Back": "Zurück",
                "OK": "OK",
                "dxPager-infoText": "Anzeige {0} von {1} ({2} Einträge)",
                "dxDataGrid-headerFilterOK": "OK",
                "dxDataGrid-headerFilterCancel": "Abbrechen",
                "dxDataGrid-editingEditRow": "Bearbeiten",
                "dxDataGrid-editingSaveRowChanges": "Speichern",
                "dxDataGrid-editingCancelRowChanges": "Änderungen verwerfen",
                "dxDataGrid-editingDeleteRow": "Löschen",
                "dxDataGrid-filterBuilderPopupTitle": "Filter-Generator",
                "dxDataGrid-filterPanelCreateFilter": "Filter erstellen",
                "dxDataGrid-filterPanelClearFilter": "Löschen",
                "dxDataGrid-filterPanelFilterEnabledHint": "Filter aktivieren",
                "dxDataGrid-editingUndeleteRow": "Löschen rückgängig machen",
                "dxDataGrid-editingConfirmDeleteMessage": "Möchten Sie diesen Datensatz wirklich löschen?",
                "dxDataGrid-validationCancelChanges": "Änderungen verwerfen",
                "dxDiagram-dialogButtonOK": "OK",
                "dxDiagram-dialogButtonCancel": "Abbrechen",
                "dxDataGrid-exportTo": "Exportieren",
                "dxDataGrid-exportToExcel": "In Excel exportieren",
                "dxDataGrid-exporting": "Exportieren...",
                "dxDataGrid-excelFormat": "Excel-Datei",
                "dxDataGrid-selectedRows": "Ausgewählte Zeilen",
                "dxDataGrid-exportSelectedRows": "Ausgewählte Zeilen exportieren",
                "dxDataGrid-exportAll": "Alle Datensätze exportieren",
                "dxDataGrid-searchPanelPlaceholder": "Suchen...",
                "dxDataGrid-noDataText": "Keine Daten",
                "dxDataGrid-headerFilterEmptyValue": "(Leer)",
                "dxDataGrid-filterRowShowAllText": "(Alle)",
                "dxScheduler-editorLabelTitle": "Titel",
                "dxScheduler-editorLabelStartDate": "Startdatum",
                "dxScheduler-editorLabelEndDate": "Enddatum",
                "dxScheduler-editorLabelDescription": "Beschreibung",
                "dxScheduler-editorLabelRecurrence": "Wiederholung",
                "dxScheduler-openAppointment": "Termin öffnen",
                "dxScheduler-recurrenceNever": "Nie",
                "dxScheduler-recurrenceMinutely": "Minütlich",
                "dxScheduler-recurrenceHourly": "Stündlich",
                "dxScheduler-recurrenceDaily": "Täglich",
                "dxScheduler-recurrenceWeekly": "Wöchentlich",
                "dxScheduler-recurrenceMonthly": "Monatlich",
                "dxScheduler-recurrenceYearly": "Jährlich",
                "dxScheduler-recurrenceRepeatEvery": "Alle",
                "dxScheduler-recurrenceRepeatOn": "Am",
                "dxScheduler-recurrenceEnd": "Ende",
                "dxScheduler-recurrenceAfter": "Nach",
                "dxScheduler-recurrenceOn": "Am",
                "dxScheduler-recurrenceRepeatMinutely": "Minute(n)",
                "dxScheduler-recurrenceRepeatHourly": "Stunde(n)",
                "dxScheduler-recurrenceRepeatDaily": "Tag(e)",
                "dxScheduler-recurrenceRepeatWeekly": "Woche(n)",
                "dxScheduler-recurrenceRepeatMonthly": "Monat(e)",
                "dxScheduler-recurrenceRepeatYearly": "Jahr(e)",
                "dxScheduler-switcherDay": "Tag",
                "dxScheduler-switcherWeek": "Woche",
                "dxScheduler-switcherWorkWeek": "Arbeitswoche",
                "dxScheduler-switcherMonth": "Monat",
                "dxScheduler-switcherAgenda": "Agenda",
                "dxScheduler-switcherTimelineDay": "Zeitleiste Tag",
                "dxScheduler-switcherTimelineWeek": "Zeitleiste Woche",
                "dxScheduler-switcherTimelineWorkWeek": "Zeitleiste Arbeitswoche",
                "dxScheduler-switcherTimelineMonth": "Zeitleiste Monat",
                "dxScheduler-recurrenceRepeatOnDate": "am",
                "dxScheduler-recurrenceRepeatCount": "Ereignisse",
                "dxScheduler-allDay": "Ganztägig",
                "dxScheduler-confirmRecurrenceEditMessage": "Möchten Sie nur diesen Termin oder die gesamte Serie bearbeiten?",
                "dxScheduler-confirmRecurrenceDeleteMessage": "Möchten Sie nur diesen Termin oder die gesamte Serie löschen?",
                "dxScheduler-confirmRecurrenceEditSeries": "Serie bearbeiten",
                "dxScheduler-confirmRecurrenceDeleteSeries": "Serie löschen",
                "dxScheduler-confirmRecurrenceEditOccurrence": "Termin bearbeiten",
                "dxScheduler-confirmRecurrenceDeleteOccurrence": "Termin löschen",
                "dxScheduler-noTimezoneTitle": "Keine Zeitzone",
                "dxScheduler-moreAppointments": "Weitere Termine: {0}"
            },
            "fr": {
                "dxList-selectAll": "Sélectionner tout",
                "dxDataGrid-ariaSelectAll": "Sélectionner tout",
                "Yes": "Oui",
                "No": "Non",
                "Cancel": "Annuler",
                "Clear": "Effacer",
                "Done": "Terminé",
                "Loading": "Chargement...",
                "Select": "Sélectionner...",
                "Search": "Rechercher",
                "Back": "Retour",
                "OK": "OK",
                "dxPager-infoText": "Affichage de {0} à {1} ({2} éléments)",
                "dxDataGrid-headerFilterOK": "OK",
                "dxDataGrid-headerFilterCancel": "Annuler",
                "dxDataGrid-editingEditRow": "Modifier",
                "dxDataGrid-editingSaveRowChanges": "Enregistrer",
                "dxDataGrid-editingCancelRowChanges": "Annuler les modifications",
                "dxDataGrid-editingDeleteRow": "Supprimer",
                "dxDataGrid-filterBuilderPopupTitle": "Générateur de filtres",
                "dxDataGrid-filterPanelCreateFilter": "Créer un filtre",
                "dxDataGrid-filterPanelClearFilter": "Effacer",
                "dxDataGrid-filterPanelFilterEnabledHint": "Activer le filtre",
                "dxDataGrid-editingUndeleteRow": "Annuler la suppression",
                "dxDataGrid-editingConfirmDeleteMessage": "Voulez-vous vraiment supprimer cet enregistrement ?",
                "dxDataGrid-validationCancelChanges": "Annuler les modifications",
                "dxDiagram-dialogButtonOK": "OK",
                "dxDiagram-dialogButtonCancel": "Annuler",
                "dxDataGrid-exportTo": "Exporter vers",
                "dxDataGrid-exportToExcel": "Exporter au format Excel",
                "dxDataGrid-exporting": "Export en cours...",
                "dxDataGrid-excelFormat": "Fichier Excel",
                "dxDataGrid-selectedRows": "Lignes sélectionnées",
                "dxDataGrid-exportSelectedRows": "Exporter les lignes sélectionnées",
                "dxDataGrid-exportAll": "Exporter toutes les données",
                "dxDataGrid-searchPanelPlaceholder": "Rechercher...",
                "dxDataGrid-noDataText": "Aucune donnée",
                "dxDataGrid-headerFilterEmptyValue": "(Valeur vide)",
                "dxDataGrid-filterRowShowAllText": "(Tout)",
                "dxScheduler-editorLabelTitle": "Titre",
                "dxScheduler-editorLabelStartDate": "Date de début",
                "dxScheduler-editorLabelEndDate": "Date de fin",
                "dxScheduler-editorLabelDescription": "Description",
                "dxScheduler-editorLabelRecurrence": "Récurrence",
                "dxScheduler-openAppointment": "Ouvrir le rendez-vous",
                "dxScheduler-recurrenceNever": "Jamais",
                "dxScheduler-recurrenceMinutely": "Toutes les minutes",
                "dxScheduler-recurrenceHourly": "Toutes les heures",
                "dxScheduler-recurrenceDaily": "Quotidienne",
                "dxScheduler-recurrenceWeekly": "Hebdomadaire",
                "dxScheduler-recurrenceMonthly": "Mensuelle",
                "dxScheduler-recurrenceYearly": "Annuelle",
                "dxScheduler-recurrenceRepeatEvery": "Répéter chaque",
                "dxScheduler-recurrenceRepeatOn": "Répéter sur",
                "dxScheduler-recurrenceEnd": "Fin",
                "dxScheduler-recurrenceAfter": "Après",
                "dxScheduler-recurrenceOn": "Sur",
                "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
                "dxScheduler-recurrenceRepeatHourly": "heure(s)",
                "dxScheduler-recurrenceRepeatDaily": "jour(s)",
                "dxScheduler-recurrenceRepeatWeekly": "semaine(s)",
                "dxScheduler-recurrenceRepeatMonthly": "mois",
                "dxScheduler-recurrenceRepeatYearly": "année(s)",
                "dxScheduler-switcherDay": "Jour",
                "dxScheduler-switcherWeek": "Semaine",
                "dxScheduler-switcherWorkWeek": "Semaine de travail",
                "dxScheduler-switcherMonth": "Mois",
                "dxScheduler-switcherAgenda": "Agenda",
                "dxScheduler-switcherTimelineDay": "Chronologie quotidienne",
                "dxScheduler-switcherTimelineWeek": "Chronologie hebdomadaire",
                "dxScheduler-switcherTimelineWorkWeek": "Chronologie de la semaine de travail",
                "dxScheduler-switcherTimelineMonth": "Chronologie mensuelle",
                "dxScheduler-recurrenceRepeatOnDate": "le",
                "dxScheduler-recurrenceRepeatCount": "événements",
                "dxScheduler-allDay": "Toute la journée",
                "dxScheduler-confirmRecurrenceEditMessage": "Voulez-vous modifier seulement cet événement ou toute la série ?",
                "dxScheduler-confirmRecurrenceDeleteMessage": "Voulez-vous supprimer seulement cet événement ou toute la série ?",
                "dxScheduler-confirmRecurrenceEditSeries": "Modifier la série",
                "dxScheduler-confirmRecurrenceDeleteSeries": "Supprimer la série",
                "dxScheduler-confirmRecurrenceEditOccurrence": "Modifier l'événement",
                "dxScheduler-confirmRecurrenceDeleteOccurrence": "Supprimer l'événement",
                "dxScheduler-noTimezoneTitle": "Pas de fuseau horaire",
                "dxScheduler-moreAppointments": "Plus de rendez-vous : {0}"
            },
            "go": {
                "dxList-selectAll": "ყველას შერჩევა",
                "dxDataGrid-ariaSelectAll": "ყველას შერჩევა",
                "Yes": "დიახ",
                "No": "არა",
                "Cancel": "გაუქმება",
                "Clear": "გასუფთავება",
                "Done": "დასრულება",
                "Loading": "იტვირთება...",
                "Select": "არჩევა...",
                "Search": "ძებნა",
                "Back": "უკან",
                "OK": "დიახ",
                "dxPager-infoText": "ჩვენება {0} - {1} ({2} ჩანაწერი)",
                "dxDataGrid-headerFilterOK": "დიახ",
                "dxDataGrid-headerFilterCancel": "გაუქმება",
                "dxDataGrid-editingEditRow": "რედაქტირება",
                "dxDataGrid-editingSaveRowChanges": "შენახვა",
                "dxDataGrid-editingCancelRowChanges": "შეწყვეტა",
                "dxDataGrid-editingDeleteRow": "წაშლა",
                "dxDataGrid-filterBuilderPopupTitle": "ფილტრის შემქმნელი",
                "dxDataGrid-filterPanelCreateFilter": "ფილტრის შემქმნელი",
                "dxDataGrid-filterPanelClearFilter": "გასუფთავება",
                "dxDataGrid-filterPanelFilterEnabledHint": "გააქტიურეთ ფილტრი",
                "dxDataGrid-editingUndeleteRow": "წაშლის გაუქმება",
                "dxDataGrid-editingConfirmDeleteMessage": "ნამდვილად გსურთ ჩანაწერის წაშლა?",
                "dxDataGrid-validationCancelChanges": "ცვლილებების გაუქმება",
                "dxDiagram-dialogButtonOK": "დიახ",
                "dxDiagram-dialogButtonCancel": "გაუქმება",
                "dxDataGrid-exportTo": "ექსპორტი",
                "dxDataGrid-exportToExcel": "Excel-ში ექსპორტი",
                "dxDataGrid-exporting": "ექსპორტი იძებნება...",
                "dxDataGrid-excelFormat": "Excel ფაილი",
                "dxDataGrid-selectedRows": "შერჩეული რიგები",
                "dxDataGrid-exportSelectedRows": "შერჩეული რიგების ექსპორტი",
                "dxDataGrid-exportAll": "ყველა ჩანაწერის ექსპორტი",
                "dxDataGrid-searchPanelPlaceholder": "ძებნა...",
                "dxDataGrid-noDataText": "მონაცემები არ არის",
                "dxDataGrid-headerFilterEmptyValue": "(ცარიელი მონაცემები)",
                "dxDataGrid-filterRowShowAllText": "(ყველა)",
                "dxScheduler-editorLabelTitle": "სათაური",
                "dxScheduler-editorLabelStartDate": "დაწყების თარიღი",
                "dxScheduler-editorLabelEndDate": "დამთავრების თარიღი",
                "dxScheduler-editorLabelDescription": "აღწერა",
                "dxScheduler-editorLabelRecurrence": "განმეორება",
                "dxScheduler-openAppointment": "კალენდარის ჩართვა",
                "dxScheduler-recurrenceNever": "არასდროს",
                "dxScheduler-recurrenceMinutely": "წუთებში",
                "dxScheduler-recurrenceHourly": "საათებში",
                "dxScheduler-recurrenceDaily": "დღეს",
                "dxScheduler-recurrenceWeekly": "კვირაში",
                "dxScheduler-recurrenceMonthly": "თვეში",
                "dxScheduler-recurrenceYearly": "წლებში",
                "dxScheduler-recurrenceRepeatEvery": "ყველა",
                "dxScheduler-recurrenceRepeatOn": "რეპეტირება",
                "dxScheduler-recurrenceEnd": "დასრულება",
                "dxScheduler-recurrenceAfter": "შემდეგ",
                "dxScheduler-recurrenceOn": "რეპეტირება",
                "dxScheduler-recurrenceRepeatMinutely": "წუთებში",
                "dxScheduler-recurrenceRepeatHourly": "საათებში",
                "dxScheduler-recurrenceRepeatDaily": "დღეს",
                "dxScheduler-recurrenceRepeatWeekly": "კვირაში",
                "dxScheduler-recurrenceRepeatMonthly": "თვეში",
                "dxScheduler-recurrenceRepeatYearly": "წლებში",
                "dxScheduler-switcherDay": "დღე",
                "dxScheduler-switcherWeek": "კვირა",
                "dxScheduler-switcherWorkWeek": "სამუშაო კვირა",
                "dxScheduler-switcherMonth": "თვე",
                "dxScheduler-switcherAgenda": "დღის წესები",
                "dxScheduler-switcherTimelineDay": "დღის აღმოსავლეთი",
                "dxScheduler-switcherTimelineWeek": "კვირის აღმოსავლეთი",
                "dxScheduler-switcherTimelineWorkWeek": "სამუშაო კვირის აღმოსავლეთი",
                "dxScheduler-switcherTimelineMonth": "თვესავლეთი",
                "dxScheduler-recurrenceRepeatOnDate": "თარიღზე",
                "dxScheduler-recurrenceRepeatCount": "მოვლენები",
                "dxScheduler-allDay": "მთელ დღეს",
                "dxScheduler-confirmRecurrenceEditMessage": "გსურთ რედაქტირება მხოლოდ ამ ღონისძიებაში თუ მთელ სერიაში?",
                "dxScheduler-confirmRecurrenceDeleteMessage": "გსურთ წაშლა მხოლოდ ამ ღონისძიებაში თუ მთელ სერიაში?",
                "dxScheduler-confirmRecurrenceEditSeries": "სერიის რედაქტირება",
                "dxScheduler-confirmRecurrenceDeleteSeries": "სერიის წაშლა",
                "dxScheduler-confirmRecurrenceEditOccurrence": "ღონისძიების რედაქტირება",
                "dxScheduler-confirmRecurrenceDeleteOccurrence": "ღონისძიების წაშლა",
                "dxScheduler-noTimezoneTitle": "დროული სარის ცენტრი არ არის",
                "dxScheduler-moreAppointments": "კიდევ {0}"
            },
            "it": {
                "dxList-selectAll": "Seleziona tutto",
                "dxDataGrid-ariaSelectAll": "Seleziona tutto",
                "Yes": "Sì",
                "No": "No",
                "Cancel": "Annulla",
                "Clear": "Cancella",
                "Done": "Fatto",
                "Loading": "Caricamento in corso...",
                "Select": "Seleziona...",
                "Search": "Cerca",
                "Back": "Indietro",
                "OK": "OK",
                "dxPager-infoText": "Visualizzazione da {0} a {1} di {2} elementi",
                "dxDataGrid-headerFilterOK": "OK",
                "dxDataGrid-headerFilterCancel": "Annulla",
                "dxDataGrid-editingEditRow": "Modifica",
                "dxDataGrid-editingSaveRowChanges": "Salva",
                "dxDataGrid-editingCancelRowChanges": "Annulla modifiche",
                "dxDataGrid-editingDeleteRow": "Elimina",
                "dxDataGrid-filterBuilderPopupTitle": "Generatore di filtri",
                "dxDataGrid-filterPanelCreateFilter": "Crea filtro",
                "dxDataGrid-filterPanelClearFilter": "Cancella",
                "dxDataGrid-filterPanelFilterEnabledHint": "Abilita il filtro",
                "dxDataGrid-editingUndeleteRow": "Annulla eliminazione",
                "dxDataGrid-editingConfirmDeleteMessage": "Sei sicuro di voler eliminare questo record?",
                "dxDataGrid-validationCancelChanges": "Annulla modifiche",
                "dxDiagram-dialogButtonOK": "OK",
                "dxDiagram-dialogButtonCancel": "Annulla",
                "dxDataGrid-exportTo": "Esporta",
                "dxDataGrid-exportToExcel": "Esporta in Excel",
                "dxDataGrid-exporting": "Esportazione in corso...",
                "dxDataGrid-excelFormat": "File Excel",
                "dxDataGrid-selectedRows": "Righe selezionate",
                "dxDataGrid-exportSelectedRows": "Esporta righe selezionate",
                "dxDataGrid-exportAll": "Esporta tutti i dati",
                "dxDataGrid-searchPanelPlaceholder": "Cerca...",
                "dxDataGrid-noDataText": "Nessun dato",
                "dxDataGrid-headerFilterEmptyValue": "(Valore vuoto)",
                "dxDataGrid-filterRowShowAllText": "(Tutto)",
                "dxScheduler-editorLabelTitle": "Titolo",
                "dxScheduler-editorLabelStartDate": "Data inizio",
                "dxScheduler-editorLabelEndDate": "Data fine",
                "dxScheduler-editorLabelDescription": "Descrizione",
                "dxScheduler-editorLabelRecurrence": "Ricorrenza",
                "dxScheduler-openAppointment": "Apri appuntamento",
                "dxScheduler-recurrenceNever": "Mai",
                "dxScheduler-recurrenceMinutely": "Ogni minuto",
                "dxScheduler-recurrenceHourly": "Ogni ora",
                "dxScheduler-recurrenceDaily": "Giornalmente",
                "dxScheduler-recurrenceWeekly": "Settimanalmente",
                "dxScheduler-recurrenceMonthly": "Mensilmente",
                "dxScheduler-recurrenceYearly": "Annualmente",
                "dxScheduler-recurrenceRepeatEvery": "Ripeti ogni",
                "dxScheduler-recurrenceRepeatOn": "Ripeti su",
                "dxScheduler-recurrenceEnd": "Fine",
                "dxScheduler-recurrenceAfter": "Dopo",
                "dxScheduler-recurrenceOn": "Il",
                "dxScheduler-recurrenceRepeatMinutely": "minuto(i)",
                "dxScheduler-recurrenceRepeatHourly": "ora(e)",
                "dxScheduler-recurrenceRepeatDaily": "giorno(i)",
                "dxScheduler-recurrenceRepeatWeekly": "settimana(e)",
                "dxScheduler-recurrenceRepeatMonthly": "mese(i)",
                "dxScheduler-recurrenceRepeatYearly": "anno(i)",
                "dxScheduler-switcherDay": "Giorno",
                "dxScheduler-switcherWeek": "Settimana",
                "dxScheduler-switcherWorkWeek": "Settimana lavorativa",
                "dxScheduler-switcherMonth": "Mese",
                "dxScheduler-switcherAgenda": "Agenda",
                "dxScheduler-switcherTimelineDay": "Linea del tempo giornaliera",
                "dxScheduler-switcherTimelineWeek": "Linea del tempo settimanale",
                "dxScheduler-switcherTimelineWorkWeek": "Linea del tempo settimana lavorativa",
                "dxScheduler-switcherTimelineMonth": "Linea del tempo mensile",
                "dxScheduler-recurrenceRepeatOnDate": "in data",
                "dxScheduler-recurrenceRepeatCount": "eventi",
                "dxScheduler-allDay": "Tutto il giorno",
                "dxScheduler-confirmRecurrenceEditMessage": "Desideri modificare solo questo appuntamento o l'intera serie?",
                "dxScheduler-confirmRecurrenceDeleteMessage": "Desideri eliminare solo questo appuntamento o l'intera serie?",
                "dxScheduler-confirmRecurrenceEditSeries": "Modifica serie",
                "dxScheduler-confirmRecurrenceDeleteSeries": "Elimina serie",
                "dxScheduler-confirmRecurrenceEditOccurrence": "Modifica appuntamento",
                "dxScheduler-confirmRecurrenceDeleteOccurrence": "Elimina appuntamento",
                "dxScheduler-noTimezoneTitle": "Nessun fuso orario",
                "dxScheduler-moreAppointments": "Altri {0}"
            },
            "nl": {
                "dxList-selectAll": "Alles selecteren",
                "dxDataGrid-ariaSelectAll": "Alles selecteren",
                "Yes": "Ja",
                "No": "Nee",
                "Cancel": "Annuleren",
                "Clear": "Wissen",
                "Done": "Klaar",
                "Loading": "Laden...",
                "Select": "Selecteer...",
                "Search": "Zoeken",
                "Back": "Terug",
                "OK": "OK",
                "dxPager-infoText": "Weergave {0} - {1} van {2} items",
                "dxDataGrid-headerFilterOK": "OK",
                "dxDataGrid-headerFilterCancel": "Annuleren",
                "dxDataGrid-editingEditRow": "Bewerken",
                "dxDataGrid-editingSaveRowChanges": "Opslaan",
                "dxDataGrid-editingCancelRowChanges": "Wijzigingen annuleren",
                "dxDataGrid-editingDeleteRow": "Verwijderen",
                "dxDataGrid-filterBuilderPopupTitle": "Filtergenerator",
                "dxDataGrid-filterPanelCreateFilter": "Filter maken",
                "dxDataGrid-filterPanelClearFilter": "Wissen",
                "dxDataGrid-filterPanelFilterEnabledHint": "Filter inschakelen",
                "dxDataGrid-editingUndeleteRow": "Verwijdering annuleren",
                "dxDataGrid-editingConfirmDeleteMessage": "Weet u zeker dat u dit record wilt verwijderen?",
                "dxDataGrid-validationCancelChanges": "Wijzigingen annuleren",
                "dxDiagram-dialogButtonOK": "OK",
                "dxDiagram-dialogButtonCancel": "Annuleren",
                "dxDataGrid-exportTo": "Exporteren naar",
                "dxDataGrid-exportToExcel": "Exporteren naar Excel",
                "dxDataGrid-exporting": "Exporteren...",
                "dxDataGrid-excelFormat": "Excel-bestand",
                "dxDataGrid-selectedRows": "Geselecteerde rijen",
                "dxDataGrid-exportSelectedRows": "Geselecteerde rijen exporteren",
                "dxDataGrid-exportAll": "Alle gegevens exporteren",
                "dxDataGrid-searchPanelPlaceholder": "Zoeken...",
                "dxDataGrid-noDataText": "Geen gegevens",
                "dxDataGrid-headerFilterEmptyValue": "(Lege waarde)",
                "dxDataGrid-filterRowShowAllText": "(Alles)",
                "dxScheduler-editorLabelTitle": "Titel",
                "dxScheduler-editorLabelStartDate": "Begindatum",
                "dxScheduler-editorLabelEndDate": "Einddatum",
                "dxScheduler-editorLabelDescription": "Omschrijving",
                "dxScheduler-editorLabelRecurrence": "Terugkeren",
                "dxScheduler-openAppointment": "Afspraak openen",
                "dxScheduler-recurrenceNever": "Nooit",
                "dxScheduler-recurrenceMinutely": "Elke minuut",
                "dxScheduler-recurrenceHourly": "Elk uur",
                "dxScheduler-recurrenceDaily": "Dagelijks",
                "dxScheduler-recurrenceWeekly": "Wekelijks",
                "dxScheduler-recurrenceMonthly": "Maandelijks",
                "dxScheduler-recurrenceYearly": "Jaarlijks",
                "dxScheduler-recurrenceRepeatEvery": "Herhaal elke",
                "dxScheduler-recurrenceRepeatOn": "Herhalen op",
                "dxScheduler-recurrenceEnd": "Einde",
                "dxScheduler-recurrenceAfter": "Na",
                "dxScheduler-recurrenceOn": "Op",
                "dxScheduler-recurrenceRepeatMinutely": "minuut(en)",
                "dxScheduler-recurrenceRepeatHourly": "uur/uur)",
                "dxScheduler-recurrenceRepeatDaily": "dag(en)",
                "dxScheduler-recurrenceRepeatWeekly": "week/week(en)",
                "dxScheduler-recurrenceRepeatMonthly": "maand(en)",
                "dxScheduler-recurrenceRepeatYearly": "jaar(en)",
                "dxScheduler-switcherDay": "Dag",
                "dxScheduler-switcherWeek": "Week",
                "dxScheduler-switcherWorkWeek": "Werkweek",
                "dxScheduler-switcherMonth": "Maand",
                "dxScheduler-switcherAgenda": "Agenda",
                "dxScheduler-switcherTimelineDay": "Tijdlijn Dag",
                "dxScheduler-switcherTimelineWeek": "Tijdlijn Week",
                "dxScheduler-switcherTimelineWorkWeek": "Tijdlijn Werkweek",
                "dxScheduler-switcherTimelineMonth": "Tijdlijn Maand",
                "dxScheduler-recurrenceRepeatOnDate": "op datum",
                "dxScheduler-recurrenceRepeatCount": "gebeurtenissen",
                "dxScheduler-allDay": "Hele dag",
                "dxScheduler-confirmRecurrenceEditMessage": "Wilt u alleen deze afspraak of de hele serie bewerken?",
                "dxScheduler-confirmRecurrenceDeleteMessage": "Wilt u alleen deze afspraak of de hele serie verwijderen?",
                "dxScheduler-confirmRecurrenceEditSeries": "Serie bewerken",
                "dxScheduler-confirmRecurrenceDeleteSeries": "Serie verwijderen",
                "dxScheduler-confirmRecurrenceEditOccurrence": "Afspraak bewerken",
                "dxScheduler-confirmRecurrenceDeleteOccurrence": "Afspraak verwijderen",
                "dxScheduler-noTimezoneTitle": "Geen tijdzone",
                "dxScheduler-moreAppointments": "Nog {0}"
            }
        });
    }
}
