import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { AppInfoService } from '../../../../../@shared/services/common';
import { CoreMenuService } from '../../core-menu.service';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {

    constructor(private menuService: CoreMenuService, private appInfo: AppInfoService) {
        if (appInfo.currentUser.tabs && appInfo.currentUser.tabs.includes(6)) {
            this.analiticsIsTrue = true;
        } else {
            this.analiticsIsTrue = false;
        }
    }

    @Input()
    item: CoreMenuItem;
    analiticsIsTrue: boolean;
    onMenuItemClick(item: any) {
        this.menuService.onMenuItemClickEvent(item)
    }
}
