import { Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2, ViewEncapsulation, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';
import notify from "devextreme/ui/notify";
import { menu } from 'app/menu/menu';

import { DataRefreshModel } from '@shared/models/datarefresh-model';
import { WebMessageService } from '@shared/services/api/webmessage.service';
import { ReOpenFormModel } from '@shared/models/reopenform-model';
import { OpenLoadingModel } from '@shared/models/openloading-model';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, Router } from '@angular/router';

import { AppInfoService } from '@shared/services/common/app-info.service';
import { EventEmitterService } from '@shared/services/common/event-emitter.service';
import { AuthService, FormService, GenericService, ReportService, TaskService } from '@shared/services/api';
import { KanbanHubModel } from '../../../@shared/models/kanban-hub-model';
import { JoblistChatHubModel, NotificationHubModel } from '../../../@shared/models/notification-hub-model';
import { ProfileHubModel } from '../../../@shared/models/profile-hub-model';
import { ComponentService } from '../../../@shared/services/common';
import { WebmsgModel } from '../../../@shared/models/webmsg-model';
import { jobListChatModel } from '../../pages/home/joblist_chat.model';
@Component({
    selector: 'auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
    // Public
    public coreConfig: any;
    public menu: any;
    public defaultLanguage: 'en'; // This language will be used as a fallback when a translation isn't found in the current language
    public appLanguage: 'en'; // Set application default language i.e fr

    // Private
    private _unsubscribeAll: Subject<void>;
    private schemaList: any[] = [];
    public useTheme = true;
    private _activatedRouteSnapshot: ActivatedRouteSnapshot;
    isLogIn: boolean = false;
    languageLocal: any = "";
    message_screen = new WebmsgModel();
    private currentRoute = "";
    webMessagePopupContainer = true;
    // currentLang = "";
    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {Title} _title
     * @param {Renderer2} _renderer
     * @param {ElementRef} _elementRef
     * @param {CoreConfigService} _coreConfigService
     * @param {CoreSidebarService} _coreSidebarService
     * @param {CoreLoadingScreenService} _coreLoadingScreenService
     * @param {CoreMenuService} _coreMenuService
     * @param {CoreTranslationService} _coreTranslationService
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _title: Title,
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
        private _coreConfigService: CoreConfigService,
        private _coreSidebarService: CoreSidebarService,
        private _coreLoadingScreenService: CoreLoadingScreenService,
        private _coreMenuService: CoreMenuService,
        private _coreTranslationService: CoreTranslationService,
        private _translateService: TranslateService,
        private _reportingService: ReportService,
        private _appInfoService: AppInfoService,
        private _eventEmitterService: EventEmitterService,
        private _taskService: TaskService,
        private _webMessageService: WebMessageService,
        private _ngZone: NgZone,
        private _router: Router,
        private _formService: FormService,
        private _activatedRoute: ActivatedRoute,
        private componentService: ComponentService,
        private genericService: GenericService,
        private authService: AuthService
    ) {

        this._unsubscribeAll = new Subject();
        this.authService._isLoggedIn.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
          
            
            //if (data) {
            //    const lang = this._appInfoService.currentUser.deflang;
            //    if (lang == "TRTR" || lang == "ENEN") {
            //        this.genericService.getlogininit(lang, "dictionary").subscribe((result: any) => {
            //
            //            //localStorage.setItem("languageSourceStorage", JSON.stringify(result.languages));
            //            //this.componentService.languageSourceObject = JSON.parse(localStorage.getItem("languageSourceStorage"));
            //            //this.componentService.languageSource = this.componentService.languageSourceObject.langSource;
            //            //this.languageLocal = this.componentService.languageSourceObject.langSource;
            //            //this.componentService.setLanguageSource(this.languageLocal);
            //        });
            //    }
            //}
        }, (err) => { console.error(err) }, () => { console.log("Observe completed") })



        // Get the application main menu
        this.menu = menu;
        // Register the menu to the menu service
        this._coreMenuService.register('main', this.menu);
        // Set the main menu as our current menu
        this._coreMenuService.setCurrentMenu('main');
        // Add languages to the translation service
        // this._translateService.addLangs(['en']); // 'fr', 'de', 'pt'
        // This language will be used as a fallback when a translation isn't found in the current language

        // Set the translations for the menu
        // this._coreTranslationService.translate(menuEnglish, menuFrench, menuGerman, menuPortuguese);
        // Set the private defaults
        //this._translateService.onLangChange.subscribe(data => {
        //    this.currentLang = data.lang;
        //})

        this._router.events.subscribe(event => {
            if (event instanceof ActivationEnd) {
                //console.log(event, event.snapshot, event.snapshot.data, event.snapshot.data['useTheme'])
                if (typeof event != "undefined"
                    && typeof event.snapshot != "undefined"
                    && typeof event.snapshot.data != "undefined"
                    && typeof event.snapshot.data['useTheme'] != "undefined") {
                    if (this.useTheme != event.snapshot.data['useTheme']) {
                        this.useTheme = event.snapshot.data['useTheme'];
                    }
                }

            }
        });
                
    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        try {
            this.signalR();
            // this._translateService.addLangs(['tr']);

            // Init wave effect (Ripple effect)
            Waves.init();

            // Subscribe to config changes
            this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
                this.coreConfig = config;
                //this.coreConfig.layout.type = "vertical";
                // Set application default language.

                // Change application language? Read the ngxTranslate Fix

                // ? Use app-config.ts file to set default language
                const appLanguage = this.coreConfig.app.appLanguage || 'en';
                // this._translateService.use(appLanguage);
                // ? OR
                // ? User the current browser lang if available, if undefined use 'en'
                // const browserLang = this._translateService.getBrowserLang();
                // this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

                /**
                 * ! Fix : ngxTranslate
                 * ----------------------------------------------------------------------------------------------------
                 */

                /**
                 *
                 * Using different language than the default ('en') one i.e French?
                 * In this case, you may find the issue where application is not properly translated when your app is initialized.
                 *
                 * It's due to ngxTranslate module and below is a fix for that.
                 * Eventually we will move to the multi language implementation over to the Angular's core language service.
                 *
                 **/

                // Set the default language to 'en' and then back to 'fr'.

                setTimeout(() => {
                    this._translateService.setDefaultLang('en');
                    this._translateService.setDefaultLang(appLanguage);
                });

                /**
                 * !Fix: ngxTranslate
                 * ----------------------------------------------------------------------------------------------------
                 */

                // Layout
                //--------

                // Remove default classes first
                this._elementRef.nativeElement.classList.remove(
                    'vertical-layout',
                    'vertical-menu-modern',
                    'horizontal-layout',
                    'horizontal-menu'
                );
                // Add class based on config options
                if (this.coreConfig.layout.type === 'vertical') {
                    this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
                } else if (this.coreConfig.layout.type === 'horizontal') {
                    this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
                }

                // Navbar
                //--------

                // Remove default classes first
                this._elementRef.nativeElement.classList.remove(
                    'navbar-floating',
                    'navbar-static',
                    'navbar-sticky',
                    'navbar-hidden'
                );

                // Add class based on config options
                if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
                    this._elementRef.nativeElement.classList.add('navbar-static');
                } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
                    this._elementRef.nativeElement.classList.add('navbar-sticky');
                } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
                    this._elementRef.nativeElement.classList.add('navbar-floating');
                } else {
                    this._elementRef.nativeElement.classList.add('navbar-hidden');
                }

                // Footer
                //--------

                // Remove default classes first
                this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

                // Add class based on config options
                if (this.coreConfig.layout.footer.type === 'footer-sticky') {
                    this._elementRef.nativeElement.classList.add('footer-fixed');
                } else if (this.coreConfig.layout.footer.type === 'footer-static') {
                    this._elementRef.nativeElement.classList.add('footer-static');
                } else {
                    this._elementRef.nativeElement.classList.add('footer-hidden');
                }

                // Blank layout
                if (
                    this.coreConfig.layout.menu.hidden &&
                    this.coreConfig.layout.navbar.hidden &&
                    this.coreConfig.layout.footer.hidden
                ) {
                    this._elementRef.nativeElement.classList.add('blank-page');
                    // ! Fix: Transition issue while coming from blank page
                    if (!!this._renderer) {
                        /*
                        this._renderer.setAttribute(
                            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
                            'style',
                            'transition:none'
                        );*/
                    }
                } else {
                    this._elementRef.nativeElement.classList.remove('blank-page');
                    // ! Fix: Transition issue while coming from blank page
                    setTimeout(() => {
                        if (!!this._renderer) {
                            /*this._renderer.setAttribute(
                                this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
                                'style',
                                'transition:300ms ease all'
                            );*/
                        }
                    }, 0);
                    // If navbar hidden
                    if (this.coreConfig.layout.navbar.hidden) {
                        this._elementRef.nativeElement.classList.add('navbar-hidden');
                    }
                    // Menu (Vertical menu hidden)
                    if (this.coreConfig.layout.menu.hidden) {
                        if (!!this._renderer) {
                            //this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
                        }
                    } else {
                        this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
                    }
                    // Footer
                    if (this.coreConfig.layout.footer.hidden) {
                        this._elementRef.nativeElement.classList.add('footer-hidden');
                    }
                }

                // Skin Class (Adding to body as it requires highest priority)
                if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
                    this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
                    this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
                }
                // if (this.currentLang == "ar-AR") this.document.body.classList.add('direction-rtl');
                // else { this.document.body.classList.remove('direction-rtl');  }
            });

            // Set the application page title
            this._title.setTitle(this.coreConfig.app.appTitle);

        } catch {

        }
        this._router.events.subscribe((event: any) => {

            if (event instanceof NavigationEnd) {

                // Hide progress spinner or progress bar
                this.currentRoute = event.url;
                if (this.currentRoute.includes('formbuilder') || this.currentRoute.includes('filemanagement') || this.currentRoute.includes('reportdesigner') || this.currentRoute.includes('pagedesigner')) {
                    this.webMessagePopupContainer = false;
                } else {
                    this.webMessagePopupContainer = true;
                }
            }
        });
        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    signalR() {
        this.DataRefreshToEvents();
        this.ReOpenFormEvents();
        this.ReOpenMenuEvents();
        this.OpenLoadingEvents();
        this.ProjectManagementHub();
        this.ProfileManagementHub();
        this.NotificationManagementHub();
        this.JobListChatHub();
        this.messageSubscribeToEvents();
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebar(key): void {
        this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
    }

    private DataRefreshToEvents(): void {

        let loginUserId = this._appInfoService.currentUser.id;
        this._webMessageService.changeModule.subscribe((message: DataRefreshModel) => {
            this._ngZone.run(() => {
                
                if (message.user_Id == loginUserId) {
                    console.log(message)
                    let data_scheme = {
                        module: message.module
                    };
                    this._eventEmitterService.refreshGrid(data_scheme);

                }
            });
        });
    }

    private ReOpenFormEvents(): void {

        //let loginUserId = this._appInfoService.currentUser.id;
        this._webMessageService.reOpenForm.subscribe((message: ReOpenFormModel) => {
            this._ngZone.run(() => {
               
                if (message.userId == this._appInfoService.currentUser.id) {

                    if (this._router.url.toLowerCase() == "/app/home") {
                        let data_scheme = {
                            instanceId: message.instanceId,
                            processFormId: message.processFormId,
                            schemeId: message.schemeId
                        };
                        this._eventEmitterService.openForm(data_scheme);
                    }
                    else {
                        this._router.navigate(['/app/home'], { state: { id: message.schemeId, instanceId: message.instanceId, processFormsId: message.processFormId } });
                    }

                }
            });
        });
    }

    private ReOpenMenuEvents(): void {

        let loginUserId = this._appInfoService.currentUser.id;
        this._webMessageService.reOpenMenu.subscribe((message: ReOpenFormModel) => {
            this._ngZone.run(() => {
                if (message.userId == loginUserId) {

                    let data_menu = {
                        menuFormGuid: message.menuFormGuid,
                        name: message.name,
                        schemeId: message.schemeId,
                        formId: 0
                    };
                    this._formService.getMenuFormId(message.menuFormGuid).subscribe((result: any) => {
                        if (result) {
                            data_menu.formId = result;
                            this._eventEmitterService.openMenu(data_menu);
                        }
                    });
                }
            });
        });
    }

    private OpenLoadingEvents(): void {

        let loginUserId = this._appInfoService.currentUser.id;
        this._webMessageService.openLoading.subscribe((message: OpenLoadingModel) => {
            this._ngZone.run(() => {
                if (message.userId == loginUserId) {

                    if (this._router.url.toLowerCase() == "/app/home") {
                        let data_ = {
                            isShow: message.isShow,
                            text: message.text
                        };
                        this._eventEmitterService.setHomePopupVisibleFunc(data_);
                    }

                }
            });
        });
    }

    private ProjectManagementHub(): void {

        let loginUserId = this._appInfoService.currentUser.id;
        this._webMessageService.projectManagementEvent.subscribe((message: KanbanHubModel) => {
            this._ngZone.run(() => {
                this._eventEmitterService.projectManagementEvent(message);

            });
        });
    }

    private ProfileManagementHub(): void {

        let userId = this._appInfoService.currentUser.id;
        this._webMessageService.profileManagementEvent.subscribe((message: ProfileHubModel) => {
            this._ngZone.run(() => {
                this._eventEmitterService.profileManagementEvent(message);

            });
        });
    }
    private JobListChatHub(): void {
        
        this._webMessageService.jobListChat.subscribe((message: JoblistChatHubModel) => {
            this._ngZone.run(() => {
                this._eventEmitterService.jobListChat(message);

            });
        });
    }

    private NotificationManagementHub(): void {
        this._webMessageService.notificationManagementEvent.subscribe((message: NotificationHubModel) => {
            this._ngZone.run(() => {
                this._eventEmitterService.notificationManagementEvent(message);
            })
        })

    }
    private messageNotification(message): void {
        if (message.message_title == null || message.message_title == "") {
            notify({
                message: message.message
            }, message.message_type, 3000);
        }
        else {
            notify({
                contentTemplate: function () {
                    return $("<p />").html(("<b>" + message.message_title + "</b><div style=\"margin-top: -4px; line-height: 22px;\">" + message.message + "</div>"))
                        .attr("style", "margin-top: -44px;margin-left: 49px;margin-bottom: 0px;");
                }
            }, message.message_type, 3000);
        }
    }
    public webMessagePopupClick(): void {
        this.webMessagePopup = false;
    }
    webMessagePopup: boolean = false;
    private messageSubscribeToEvents(): void {

        let loginUserId = this._appInfoService.currentUser.id;
        this._webMessageService.messageReceived.subscribe((message: WebmsgModel) => {
            this._ngZone.run(() => {

                if (message.user_id == this._appInfoService.currentUser.id.toString()/*message.clientuniqueid !== this.uniqueID && message.senduniqueid == this.uniqueID*/) {
                    // 0: pop-up 1:bildirim 2: ikisi birden 
                    if (message.msg_show_type == 0) {
                        this.webMessagePopup = true;

                        //let result = alert(message.message, "Favori Eklenecek!");
                        //result.then((dialogResult) => {

                        //});
                        //// alert("POPUP - " +message.message);

                    }
                    else if (message.msg_show_type == 1) {
                        this.messageNotification(message);
                        //   alert("B�LD�R�M - " + message.message);
                    }
                    else {
                        this.webMessagePopup = true;
                        // message.message_type = "received";
                        //  alert("�K�S�B�RDEN - " + message.message);
                        this.messageNotification(message);
                    }

                    this.message_screen = message;

                    this._webMessageService.saveMessageInfo(message).subscribe((r: any) => {

                    });

                }
            });
        });
    }

}
