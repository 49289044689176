import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ScheduleModel } from '@shared/models/schedule-model';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { MsalService } from '@azure/msal-angular';
import {
    AuthenticationResult,
    InteractionType,
    PublicClientApplication,
} from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
@Injectable({
    providedIn: 'root'
})
export class ScheduleService {
    private apiUrl = 'https://graph.microsoft.com/v1.0/me/events'; // Takvim etkinlikleri API endpoint'i
    constructor(public appInfo: AppInfoService,
        private http: HttpClient,
        private authService: MsalService
    ) {

    }
    
    configUrl = '../../../assets/outlookServices.json';
    getConfig(): Observable<any> {
        return this.http.get(this.configUrl);
    }
    async login(): Promise<string> {
        const config = await this.getConfig().toPromise();
        const loginEventRequest = config.loginEventRequest;
       

        return new Promise<string>((resolve, reject) => {
            this.authService.loginPopup(loginEventRequest).subscribe(
                (res) => {
                 //   console.log(res);
                    console.log('Giri� ba�ar�l�');
                    resolve(res.accessToken);
                },
                (error) => {
                    console.log('Giri� hatas�:', error);
                    reject(error);
                }
            );
        });
    }

    getCalendarEvents(accessToken: string, url?: string): Observable<any> {
        const apiUrl = url || this.apiUrl; // URL parametresi varsa kullan, yoksa varsay�lan apiUrl'i kullan

        var headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http.get(apiUrl, { headers });
    }


    addEventToCalendar(graphEvent, token) {
        
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        });

        return this.http.post(this.apiUrl, JSON.stringify(graphEvent), { headers });
    }

    updateEventToCalendar(graphEvent, id, token) {
        
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        });

        const apiUrlWithId = `${this.apiUrl}/${id}`;
        console.log(apiUrlWithId)
        return this.http.patch(apiUrlWithId, JSON.stringify(graphEvent), { headers });
    }



    logout() {
        this.authService.logout();
    }

   

   


    getAllSchedule(userId: number): Observable<ScheduleModel[]> {
        let params = new HttpParams().set("userId", userId.toString());
        
        return this.http.get<ScheduleModel[]>(this.appInfo.appUrl + "api/schedule/getUserEvents", { params: params });
    }

    createEvent(schedule): Observable<ScheduleModel> {
        //console.log("Event Data:", schedule); //working
        return this.http.post<ScheduleModel>(this.appInfo.appUrl + "api/schedule/createUserEvent", JSON.stringify(schedule), this.appInfo.httpOptions);
    }

    updateEvent(schedule): Observable<ScheduleModel> {
        return this.http.post<ScheduleModel>(this.appInfo.appUrl + "api/schedule/editUserEvent", JSON.stringify(schedule), this.appInfo.httpOptions);
    }

    deleteEvent(scheduleId: string): Observable<ScheduleModel> {
       
        let params = new HttpParams().set("scheduleId", scheduleId.toString());
       
        return this.http.delete<ScheduleModel>(this.appInfo.appUrl + "api/schedule/deleteUserEvent", { params: params });
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }


}
