import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { fadeInLeft, zoomIn, fadeIn } from '@core/animations/core.animation';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { CoreLoadingScreenService } from '../../../../../@core/services/loading-screen.service';

@Component({
    selector: 'content',
    templateUrl: './content.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [fadeInLeft, zoomIn, fadeIn],
    host: { class: 'river-app-content' }
})
export class ContentComponent {
    public coreConfig: any;
    public animate;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param {CoreConfigService} _coreConfigService
     *
     */
    loadingRouteConfig = null;
    constructor(private _coreConfigService: CoreConfigService, private _coreLoadingService: CoreLoadingScreenService, private _router: Router ,private _changeDetectorRef: ChangeDetectorRef) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart) {
                this.loadingRouteConfig = true;
            } else if (event instanceof RouteConfigLoadEnd) {
                this.loadingRouteConfig = false;
            }
        });
    }

    ngAfterContentChecked() {
        this._changeDetectorRef.detectChanges();
    }

    /**
     * Fade In Left Animation
     *
     * @param outlet
     */
    fadeInLeft(outlet) {
        if (typeof outlet == "undefined") {
            return null;
        }
        if (this.animate === 'fadeInLeft') {
            return outlet.activatedRouteData.animation;
        }
        return null;
    }

    /**
     * Zoom In Animation
     *
     * @param outlet
     */
    zoomIn(outlet) {
        if (typeof outlet == "undefined") {
            return null;
        }
        if (this.animate === 'zoomIn') {
            return outlet.activatedRouteData.animation;
        }
        return null;
    }

    /**
     * Fade In Animation
     *
     * @param outlet
     */
    fadeIn(outlet) {
        if (typeof outlet == "undefined") {
            return null;
        }
        if (this.animate === 'fadeIn') {
            return outlet.activatedRouteData.animation;
        }
        return null;
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On Init
     */
    ngOnInit(): void {
        // Subscribe config change
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
            this.animate = this.coreConfig.layout.animation;
        });
       
    }
}
