import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, NavigationExtras } from '@angular/router';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AppInfoService } from '@shared/services/common/app-info.service';
import notify from "devextreme/ui/notify";
import { catchError } from 'rxjs/operators';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { ProfileService } from '../../../app/pages/profile/profile.service';
import { EventEmitterService } from '../../../@shared/services/common';
import { WebMessageService } from './webmessage.service';

@Injectable()
export class AuthService {
    //mobileConfirmationEmit = new EventEmitter<MobileConfirmationModel>();
    _hubConnection: HubConnection;
    connectionEstablished = new EventEmitter<Boolean>();
    private connectionIsEstablished = false;

    loadPanelMobileCodeText = "Mobil uygulamanıza bildirim gönderilmiştir.Lütfen onaylama işlemini yapınız."

    loggedIn = false;
    isFormBuilder = false;
    reportingPage = false;
    typeLogin = 0;
    loadingVisibleMobileCode = false;

    public _isAuthLoading: BehaviorSubject<any>;
    public _isLoggedIn: BehaviorSubject<any>;
    myPage: number;
 
    constructor(
        private router: Router,
        //private componentService: ComponentService,
        public appInfo: AppInfoService,
        private http: HttpClient,
        public webMessageService: WebMessageService,
        private _ngZone: NgZone,
        private spinner: NgxSpinnerService,
        private profileService: ProfileService,
        private eventEmitterService: EventEmitterService) {

        this._isAuthLoading = new BehaviorSubject(false);
        this._isLoggedIn = new BehaviorSubject(false);
    }
    logInWithGuid(guid: string) {
        
        let params = new HttpParams().set("guid", guid);
        this.http.get<string>(this.appInfo.appUrl + "api/auth/loginWithGuid", { params: params }).subscribe((result: any) => {
            if (result.entity == null) {
                this.logOut();
            }
            else
                this.loginResult(result.entity, false, result.taskInfo);

        }, error => {
        });
    }

    logInWithToken(token: string, lang: string) {
        let params = new HttpParams().set("accessToken", token).set("lang", lang);
        return this.http.get<string>(this.appInfo.appUrl + "api/auth/loginwithaccesstoken", { params: params }).subscribe((result: any) => {
            if (result.entity == null)
                this.logOut();
            else
                this.loginResult(result.entity, false, result.taskInfo);

        }, error => {
        });
    }
    tempEntityAndOthers = { entity: null, rememberMe: null, taskInfo: null };

    logIn(userName: string, password: string, rememberMe: boolean, setLanguage: any) {
        //
        
        this.spinner.show("mainspinner");
        let params = new HttpParams().set("userName", userName).set("password", password).set("lang", setLanguage);
        return this.http.get<string>(this.appInfo.appUrl + "api/auth/login", { params: params }).subscribe((entity: any) => {
           
            
            //let taskInfo = { schemeId: entity.openFormId};
            let taskInfo = entity.openFormId && entity.openFormId != 0 ? { schemeId: entity.openFormId } : null;

            this.typeLogin = entity.tfaType;
            this.tempEntityAndOthers = { entity: entity, rememberMe: rememberMe, taskInfo: taskInfo };

            if (this.typeLogin == 0) {
                this.loginResult(entity, rememberMe, taskInfo);
            }
            else if (this.typeLogin == 1) {
                this.spinner.hide("mainspinner");
                this.loadPanelMobileCodeText = "Mobil uygulamanıza bildirim gönderilmiştir.Lütfen onaylama işlemini yapınız."
                this.loadingVisibleMobileCode = true;
                this.eventEmitterService.isUserLoginControl(true);
                //this.createConnection();
                this.mobileConfirmationResultEvent();
                
                    //this.startConnection();

              
            }
            else {
                this.spinner.hide("mainspinner");
            }

        }, error => {
            this.setIsAuthLoading(false);
            this.showToast("Üzgünüz, işlem sırasında bir hata oluştu:(", "error");
            console.log(error);
        });
    }

    mailConfirmation(mailCode) {
        let params = new HttpParams().set("mailCode", mailCode);
        this.http.get<string>(this.appInfo.appUrl + "api/auth/mailconfirmation", { params: params }).subscribe((rtn: any) => {
            if (rtn.success == true) {
                this.tempEntityAndOthers.entity.token = rtn.token;
                this.tempEntityAndOthers.entity.currentUser.token = rtn.token;
                this.tempEntityAndOthers.entity.teDate = rtn.teDate;
                this.tempEntityAndOthers.entity.sessionTimeout = rtn.sessionTimeout;

                this.loginResult(this.tempEntityAndOthers.entity, this.tempEntityAndOthers.rememberMe, this.tempEntityAndOthers.taskInfo);
            }
            else
                this.showToast("Girdiğiniz kod hatalı.", "warning");
        });
    }

    loginResult(entity: any, rememberMe: boolean, taskInfo: any) {
        
        if (entity.isConnect) {
            this.webMessageService.startAuthConnection();
            let googleMapKey = entity.integrationApi.GOOGLEMAP;
            localStorage.setItem("GMAPI", btoa(typeof googleMapKey != "undefined" && googleMapKey != null ? googleMapKey : ""));//AIzaSyBfeMCzcIXRN-rBUA19hqwFgngihg99VwQ

            entity.IntegrationApi = {};

            this.appInfo.RiverEntity = entity;
            this.appInfo.currentUser = entity.currentUser;

            this.appInfo.parameters = entity.parameters;

            this.appInfo.teDate = entity.teDate;
            this.appInfo.token = entity.token;
            //this.appInfo.currentUser.token = entity.token;

            localStorage.setItem("localUserSession", JSON.stringify(entity));
            sessionStorage.setItem("sessionUser", "true");

            this.loggedIn = true;
            this.eventEmitterService.isUserLoginControl(true);

            if (rememberMe) localStorage.setItem("rememberMe", "true");
            else localStorage.setItem("rememberMe", "false");

            //this.spinner.hide("mainspinner");
            //this.router.routeReuseStrategy.shouldReuseRoute = function () {
            //    return false;
            //}

            setTimeout(() => {
                this._isLoggedIn.next(true)
            }, 250)
            debugger
            var rr = this.router.url;
            if (taskInfo == null) {
                try {
                    if (entity.redirectUrl != null) {
                        if (!entity.redirectUrl.includes('pageviewer')) {
                            this.router.navigate([entity.redirectUrl]);
                        }
                        else {
                            var params = entity.redirectUrl.split('?')[1].split('&');

                            var pageId;
                            params.forEach(function (param) {
                                var [key, value] = param.split('=');
                                if (key === 'pageId') {
                                    pageId = value;
                                }
                            });

                            const navigationExtras: NavigationExtras = {
                                queryParams: { pageId: pageId }
                            };
                            this.router.navigate(['/app/pageviewer'], navigationExtras);

                        }
                    }
                    else {
                        this.router.navigate(['app/home']);
                    }
                }
                catch (ex) {
                    console.log(ex);
                    this.router.navigate(['app/home']);
                }
                
                  
               
               
            }
            else {
                sessionStorage.setItem("homeState", JSON.stringify({ id: taskInfo.schemeId, instanceId: taskInfo.instanceId, processFormsId: taskInfo.processFormsId, buttonId: taskInfo.buttonId }));
                this.router.navigate(['app/home'], { state: { id: taskInfo.schemeId, instanceId: taskInfo.instanceId, processFormsId: taskInfo.processFormsId, buttonId: taskInfo.buttonId } });
            }
        }
        else {
            try {
                this.typeLogin = 0;
                for (let i = 1; i < 6; i++)
                    (<HTMLInputElement>document.getElementById("mCInput" + i)).value = "";


            } catch (ex) {

            }
            this.spinner.hide("mainspinner");
            this.showToast(entity.message, "warning");
        }
        this.setIsAuthLoading(false);
       // console.log(this.appInfo)
    }

    redirectTo(uri: string) {

        let _domain = window.location.protocol + "//" + window.location.host;
        window.open(_domain, "_self");
        //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        //    this.router.navigate([uri], { state: { pageUuid: this.componentService.createuuid() } }));
    }

    updateColor(formData) {
        let params = new HttpParams().set("id", formData.id).set("color", formData.color);
        return this.http.get(this.appInfo.appUrl + "api/auth/updatecolor", { params: params });
    }

    updateProfile(profileData): Observable<boolean> {
        
        return this.http.post<boolean>(this.appInfo.appUrl + "api/auth/updateprofile", JSON.stringify(profileData), this.appInfo.httpOptions);
    }

    logOut() {
        this.eventEmitterService.isUserLoginControl(false);
        this._isLoggedIn.next(false)
        this.loggedIn = false;
        localStorage.setItem("rememberMe", "false");
        sessionStorage.setItem("sessionUser", "false");
        localStorage.removeItem("localUserSession");
        localStorage.setItem("GMAPI", "");
        this.webMessageService.stopConnection();
        //this.router.routeReuseStrategy.shouldReuseRoute = function () {
        //    return false;
        //}

        //this.router.onSameUrlNavigation = 'reload';
        //this.router.navigate(['/login'], { state: { pageUuid: this.componentService.createuuid() } });
    }

    get isLoggedIn() {
        return this.loggedIn;
    }

    get localUser() {
        let localUserSession = localStorage.getItem("localUserSession");
        if (!localUserSession || localUserSession == null) return null;
        let entity = JSON.parse(localUserSession);
        return entity.currentUser;
    }

    showToast(message, status) {
        notify(message, status, 3000);
    }

    get _isLoading(): Observable<any> {
        return this._isAuthLoading.asObservable();
    }

    setIsAuthLoading(value): void {
        this._isAuthLoading.next(value);
    }

    set_isLoggedIn(value): void {
        this._isLoggedIn.next(value);
    }

    private createConnection() {
        
        this._hubConnection = new HubConnectionBuilder()
                    .withUrl(this.appInfo.appUrl + 'RiverHub')
                    .build();

                this._hubConnection.onclose(() => {
                    let _this = this;
                    setTimeout(function () {
                        _this.startConnection(), 5000
                    })
                
            
        })
      

    }

    private startConnection(): void {
       
                this._hubConnection
                    .start()
                    .then(() => {
                        this.connectionIsEstablished = true;
                        this.connectionEstablished.emit(true);
                    })
                    .catch(err => {
                        let _this = this;
                        setTimeout(function () { _this.startConnection(); }, 2000);
                    
            
        })
       
            
         
    

    }
    private mobileConfirmationResultEvent(): void {
        let _this = this;
        this._hubConnection.on('MobileConfirmationResult', (data: any) => {
            //this.mobileConfirmationEmit.emit(data);
            let _temp_ = this;
            if (data.userId == _this.tempEntityAndOthers.entity.currentUser.id) {
                if (data.webmobConfCode == _this.tempEntityAndOthers.entity.webmobileConfirmationCode) {

                    _this.loadPanelMobileCodeText = "Onaylama işlemi başarılı. Anasayfaya yönlendiriliyorsunuz."
                    setTimeout(function () {
                        _this.tempEntityAndOthers.entity.token = data.token;
                        _this.tempEntityAndOthers.entity.currentUser.token = data.token;
                        _this.tempEntityAndOthers.entity.teDate = data.teDate;
                        _this.tempEntityAndOthers.entity.sessionTimeout = data.sessionTimeout;

                        _this.loginResult(_this.tempEntityAndOthers.entity, _this.tempEntityAndOthers.rememberMe, _this.tempEntityAndOthers.taskInfo);
                    }, 1000);

                }
                else {
                    _this.loadPanelMobileCodeText = "Onaylama işleminde hata oluştu. Sayfayı yenileyerek yeniden giriş yapınız."
                }
            }
        });

        //let _this = this;
        //this.mobileConfirmationEmit.subscribe((message: MobileConfirmationModel) => {
        //    this._ngZone.run(() => {

        //    })
        //});
    }
}

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private authService: AuthService, private appInfo: AppInfoService, public webMessageService: WebMessageService) { }

    redirectTo(uri: string) {

        let _domain = window.location.protocol + "//" + window.location.host;
        window.open(_domain, "_self");
        //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        //    this.router.navigate([uri]));
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        this.authService.isFormBuilder = false;
        this.authService.reportingPage = false;

        let rememberMe = localStorage.getItem("rememberMe");
        let _localUserSession = localStorage.getItem("localUserSession");
        let sessionUser = sessionStorage.getItem("sessionUser");
        /*if ((sessionUser == null || typeof sessionUser == "undefined") && (route.url.length > 0 && (route.url[0].path == "reportdesigner" || route.url[0].path == "filemanagement"))) {
            sessionStorage.setItem("sessionUser", "true");
            sessionUser = "true";
        }*/
        if ((_localUserSession != null && typeof _localUserSession != "undefined")) {
            sessionStorage.setItem("sessionUser", "true");
            sessionUser = "true";
        }
        if ((rememberMe && rememberMe == "true") || (sessionUser && sessionUser == "true")) {

            // if (_localUserSession && JSON.parse(_localUserSession).currentUser) {
            this.authService.loggedIn = true;
            let entity = JSON.parse(_localUserSession);
            this.appInfo.RiverEntity = entity;
            this.appInfo.currentUser = entity.currentUser;
            this.appInfo.teDate = entity.teDate;
            this.appInfo.token = entity.token;
            setTimeout(() => {
                this.authService._isLoggedIn.next(true)
            },250)
            this.webMessageService.startAuthConnection();

            //alert("Auth rememberMe");
            return true;
        }
        else {
            const isLoggedIn = this.authService.isLoggedIn;

            const isLoginForm = route.routeConfig.path === 'login';

            if (isLoggedIn && isLoginForm) {
                this.router.routeReuseStrategy.shouldReuseRoute = function () {
                    return false;
                }
                this.router.onSameUrlNavigation = 'reload';
                //this.router.navigate(['/']);
                //this.redirectTo('/app/home');
                //alert("Auth");

                setTimeout(() => {
                    this.authService._isLoggedIn.next(true)
                }, 250)
                this.webMessageService.startAuthConnection();

                return true;
            }
            else {
                //alert("not Auth");
                this.router.navigate(['/login']);
                return false;
            }

            /*if (!isLoggedIn && !isLoginForm) {
                this.router.navigate(['/login']);
            }
            return isLoggedIn || isLoginForm;*/
        }
    
    }

}

@Injectable()
export class FormbuilderGuardService implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        this.authService.isFormBuilder = true;
        return true;
    }
}

@Injectable()
export class ReportingGuardService implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        this.authService.reportingPage = true;
        return true;
    }
}


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    
    NonUpdateHeadersUrl = [
        'api/filemanagement/uploadfilechunky',
        'api/filemanagement/uploadfileNewChunky',
        'api/filemanagement/uploadfiletodmspath',
        'https://graph.microsoft.com/v1.0/me/events',
        'https://graph.microsoft.com/v1.0/me/events/',
        'api/filemanagement/downloadFromListFileManagementDataVMG'

    ]

    constructor(private router: Router, private appInfo: AppInfoService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var url = request.url.replace(this.appInfo.appUrl, '');

        if (!this.NonUpdateHeadersUrl.includes(url)) {
            if (url.includes('https://graph.microsoft.com/v1.0/')) {

            } else {
                request = request.clone(this.appInfo.httpOptions);
            }

        }

        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                this.router.navigate(['/login']);
            }
            const error = err.error || err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
