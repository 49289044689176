export const locale = {

    lang: 'ITIT',
  
    data: {
  
        loginButton: "Accedi",
        userName: "Nome Utente",
        pass: "Password",
        remember: "Ricordami",
        forgotPass: "Password Dimenticata",
        modules: "Moduli",
        generic: "Generale",
        search: "Cerca",
        logout: "Esci",
        jobListMessageArea: "area messaggi elenco lavori",
        generateLink: "generare collegamento",
        fileLink: "Collegamento al file",
        givePasswordToTheLink: "dare la password al collegamento",
        giveTheLinkAnExpirationDate: "Dai al collegamento una data di scadenza",

        waitingJob: "Miei Lavori in Sospeso",
        myStarted: "Ho Iniziato",
        myIncluded: "Sono Incluso",
        myCompleted: "Lavori Completati",
        jobList: "Lista Lavori",
        formNo: "Numero Modulo",
        status: "Stato",
        starter: "Iniziato",
        assigned: "Assegnato",
        timeoutDate: "Scadenza",
        startDate: "Data di Inizio",
        processing: "In Elaborazione",
        you: "tu",
        history: "Storico",
        description: "Descrizione",
        date: "Data",
        growShrink: "Ingrandisci/Riduci",
        close: "Chiudi",
        gridReset: "Resetta Impostazioni Griglia",
        columnSelect: "Selezione Colonne",
        jobName: "Nome Lavoro",
        keyword: "Parola Chiave",
        completion: "Completamento",
        all: "Tutti",
        completedDate: "Data di Completamento",
        completions: "Completati",
        ongoing: "In Corso",
        formName: "Nome Modulo",
        sunday: "Domenica",
        monday: "Lunedì",
        tuesday: "Martedì",
        wednesday: "Mercoledì",
        thursday: "Giovedì",
        friday: "Venerdì",
        saturday: "Sabato",
        pdfExport: "Esporta in PDF",
        sendPassword: "Invia la Mia Password",
        assignedDate: "Data Assegnazione",
        events: "Eventi",
        new: "Nuovo",
        save: "Salva",
        formula: "Formula",
        selectDataSource: "Seleziona Origine Dati",
        requiredField: "Campo Obbligatorio",
        suffix: "Suffisso",
        
  
        valueColumn: "Colonna Valore",
        showLength: "Mostra Numero di Attività nelle Colonne",
        clearButton: "Pulsante Pulisci",
        allCaps: "Tutto Maiuscolo",
        autoAsc: "Asc Automatico",
        width: "Larghezza",
        length: "Lunghezza",
        functions: "Funzioni",
        selectElement: "Seleziona Elemento",
        selectColumn: "Seleziona Colonna",
        edit: "Modifica",
        changeEventActions: "Modifica Azioni Evento",
        barcode: "Codice a Barre",
        systemInfo: "Informazioni di Sistema",
        prefix: "Prefisso",
        max: "Massimo",
        showMinMaxInfo: "Mostra Informazioni Minime e Massime",
        selectUser: "Seleziona Utente",
        spinButton: "Pulsante di Incremento",
        numberIncDecButton: "Pulsante di Incremento-Decremento Numerico",
        decimal: "Numero Decimale",
        numeral: "Numero",
        wrongFormat: "Hai inserito un formato errato",
        height: "Altezza",
        microphone: "Microfono",
        notifyDataReset: "Reset Dati",
        data: "Dati",
        dynamicData: "Dati Dinamici",
        manualData: "Dati Manuali",
        selectDataGrid: "Seleziona Griglia Dati",
        overrideUserChange: "Sovrascrivi Cambi Utente",
        textColumn: "Colonna Testo",
        makeInitialValue: "Imposta Valore Iniziale Predefinito",
        undo: "Annulla",
        copy: "Copia",
        preview: "Anteprima",
        delete: "Elimina",
        cancel: "Annulla",
        languageSettings: "Impostazioni Lingua",
        messageSqlLinkDeleted: "Poiché hai aggiunto dati manualmente, lo script SQL e l'indirizzo del collegamento saranno eliminati! Continuare?",
        messageSqlLinkDeletedForMenu: "La selezione dei dati come menu cancellerà il file di script SQL e l'indirizzo del collegamento! Vuoi procedere?",
        permissions: "Permessi",
        objectid: "ID Oggetto",
        fontSize: "Dimensione Font",
        fontWidth: "Larghezza Testo",
        fontStyle: "Stile Font",
        underline: "Sottolineato",
        textColor: "Colore Testo",
        visibility: "Visibilità",
        textAlignment: "Allineamento Testo",
        settings: "Impostazioni",
        messageUnsaved: "Sei sicuro di voler uscire senza salvare le modifiche?",
        warning: "Attenzione!",
        userAuthorizations: "Autorizzazioni Utente",
        formPreferences: "Preferenze Modulo",
        formOpeningevent: "Evento Apertura Modulo",
        chooseConnectionAddress: "Scegli Indirizzo Connessione",
        selectPriority: "Seleziona Priorità",
        loadingEditor: "Caricamento Editor...",
        showAddButton: "Mostra Pulsante Aggiungi Colonna",
        update: "Aggiorna",
        connectionID: "ID Connessione",
        formPreview: "Anteprima Modulo",
        nameDisplayedform: "Nome del Modulo Visualizzato",
        formNameVariable: "Variabile Nome Modulo",
        
  
        category: "Categoria",
        formColor: "Colore Modulo",
        generalForm: "Modulo Generale",
        saveForm: "Salva Modulo",
        messageNeworupdate: "Le modifiche apportate al modulo esistente verranno eliminate. Continuare?",
        messageDelete: "Sei sicuro di voler eliminare il modulo?",
        toastDeleted: "Modulo Eliminato",
        toastError: "Spiacenti, si è verificato un errore durante l'elaborazione",
        alertDifferentID: "Questo ID è utilizzato altrove, specificare un ID diverso.",
        confirmChanges: "Conferma le modifiche",
        toastUserpermission: "L'utente non ha il permesso di progettare moduli.",
        toastCodeeditor: "Il Code Editor esiste già",
        messageDeletesure: "Gli elementi in questo componente verranno eliminati",
        messageSure: "Sei sicuro?",
        successful: "Successo",
        error: "Errore",
        toastFormupdate: "Modulo Aggiornato",
        toastMistake: "Spiacenti, si è verificato un errore durante l'elaborazione",
        toastSaved: "Salvato",
        toastCopied: "Copiato",
        toastEmptyFormMessage: "Nessun elemento del modulo aggiunto ancora",
        toastGiveName: "Dai un nome al tuo modulo",
        toastGiveVariableName: "Dai un nome alla variabile del nome del modulo",
        toastVariableNameNumberWarning: "Il nome della variabile non può iniziare con un numero",
        formCopy: "Copia Modulo",
        messageButtonDelete: "Sei sicuro di voler eliminare il pulsante?",
        noDataFound: "Nessun Dato Trovato",
        language: "Lingua",
        filterDatagrid: "Filtraggio Datagrid",
        addManualData: "Aggiungi Dati Manualmente in Esecuzione",
        dateFormat: "Formato Data",
        messageSqlLinkDelete: "Poiché hai aggiunto dati manualmente, lo script SQL e l'indirizzo del collegamento saranno eliminati! Continuare?",
        timeOption: "Opzione Orologio",
        defaultTodaysDate: "Imposta la data di oggi di default",
        dateRange: "Intervallo di Date",
        minToday: "Minimo Oggi",
        maxToday: "Massimo Oggi",
        dateRangeDay: "Intervallo di Date (Giorno)",
        enterDateRangeValue: "Inserisci il valore dell'intervallo di date",
        imageIdColumn: "Colonna ID Immagine",
        statusSettings: "Impostazioni Stato",
        active: "Attivo",
        passive: "Passivo",
        enable: "Abilitato",
        disable: "Disabilitato",
        visible: "Visibile",
        invisible: "Invisibile",
        items: "Elementi",
        showItems: "Mostra Elementi",
        array: "Array",
        letLabelAppear: "Mostra Etichetta",
        add: "Aggiungi",
        horizontal: "Orizzontale",
        vertical: "Verticale",
        text: "Testo",
        alertFillRequiredFields: "Per favore, compila i campi obbligatori",
        alertSignatureField: "Il campo firma non può essere vuoto",
        messageApproveTransaction: "Approvi la transazione?",
        button: "Pulsante",
        reasonforRefusal: "Motivo del Rifiuto",
        enterReasonRejection: "Inserisci il Motivo del Rifiuto",
        reject: "Rifiuta",
        addDeleteButton: "Aggiungi/Elimina Pulsante",
        showBorder: "Mostra Bordo",
        buttons: "Pulsanti",
        clean: "Pulisci",
        buttonSettings: "Impostazioni Pulsante",
        saveLocation: "Posizione di Salvataggio",
        paymentButton: "Pulsante di Pagamento",
        paymentProvider: "Provider di Pagamento",
        
  
        price: "Prezzo",
        numberOfInstallments: "Numero di Rate",
        descriptionField: "Campo Descrizione",
        current: "Corrente",
        currentForm: "Modulo Corrente",
        name: "Nome",
        surname: "Cognome",
        country: "Paese",
        city: "Città",
        address: "Indirizzo",
        authorizations: "Autorizzazioni",
        users: "Utenti",
        user: "Utente",
        givePermission: "Autorizza",
        groups: "Gruppi",
        group: "Gruppo",
        saveandSend: "Salva e Invia",
        approve: "Approva",
        sendBack: "Rinvia",
        deslineAndEnd: "Rifiuta e Termina",
        complete: "Completato",
        blue: "Blu",
        gray: "Grigio",
        green: "Verde",
        red: "Rosso",
        left: "Sinistra",
        right: "Destra",
        color: "Colore",
        symbol: "Simbolo",
        position: "Posizione",
        action: "Azione",
        total: "Totale",
        filePath: "Percorso del File",
        fileName: "Nome del File",
        fileUpload: "Carica File",
        choose: "Scegli",
        updateData: "Aggiorna Dati",
        show: "Mostra",
        columns: "Colonne",
        changeEvent: "Evento di Modifica",
        columnSettings: "Impostazioni Colonna",
        columnName: "Nome Colonna",
        deleteColumn: "Elimina Colonna",
        columnSqlScripts: "Script SQL Colonna",
        imageNameColumn: "Colonna Nome File",
        columnLanguageSetting: "Impostazione Lingua Colonna",
        dataSource: "Sorgente Dati",
        filter: "Filtro",
        reset: "Reset",
        column: "Colonna",
        image: "Immagine",
        searchLocation: "Cerca Posizione...",
        draggable: "Trascinabile",
        usersCanChange: "Gli Utenti Possono Cambiare",
        multiMarker: "Multi Marker",
        markerLimit: "Limite Marcatori (0: Nessun Limite)",
        meetingType: "Tipo di Riunione",
        meetingName: "Nome Riunione",
        loadingFiles: "Caricamento file...",
        fileSizeExceeded: "Dimensione del file superata",
        filesLoaded: "File caricati",
        condition: "Condizione",
        value: "Valore",
        connectionAddress: "Indirizzo Connessione",
        imageTypeColumn: "Colonna Tipo File",
        visibleColumn: "Colonna Visibile",
        selectionMode: "Modalità di Selezione",
        selectMode: "Modalità di Selezione",
        
  
        datagridFiltering: "Filtraggio Datagrid",
        imageDataColumn: "Colonna Dati File",
        selectDataColumn: "Seleziona Colonna Datagrid",
        dataFormat: "Formato Dati",
        photoPreview: "Anteprima Foto",
        videoPreview: "Anteprima Video",
        displayPhoto: "Visualizza Foto",
        displayVideo: "Visualizza Video",
        imageColumn: "Colonna Immagine",
        videoColumn: "Colonna Video",
        columnNameAppear: "Nome Colonna da Visualizzare",
        selected: "Selezionato",
        inStock: "In Stock",
        messageSave: "Sei sicuro di voler salvare le modifiche?",
        gallery: "Galleria",
        dollar: "dollaro",
        sterling: "sterlina",
        underPhoto: "Sotto la Foto",
        topPhoto: "Foto in Alto",
        byCategory: "Per Categoria",
        dashboardShowHide: "Mostra/Nascondi Dashboard",
        showDetail: "Mostra Dettaglio",
        hideDetail: "Nascondi Dettaglio",
        decrease: "Diminuisci",
        increase: "Aumenta",
        watch: "Guarda",
        watchMovie: "Guarda Film",
        noRecordsFound: "Nessun Record Trovato!",
        appearance: "Aspetto",
        numberofColumns: "Numero di Colonne",
        pagingRecords: "Paginazione Record",
        detailLocation: "Posizione Dettagli",
        hideImages: "Nascondi Immagini",
        quantityCategoryDisable: "Disabilita Categoria Quando Quantità Aumenta",
        general: "Generale",
        idColumn: "Colonna ID",
        availableQuantity: "Quantità Disponibile",
        categoryColumn: "Colonna Categoria",
        photoColumn: "Colonna Foto",
        titleColumn: "Colonna Titolo",
        priceColumn: "Colonna Prezzo",
        updatablePrice: "Prezzo Aggiornabile",
        videoGallery: "Galleria Video",
        uniqueComponent: "Componente Unico",
        showVariant: "Mostra Variante",
        transferTotalAmount: "Trasferisci Importo Totale",
        title: "Titolo",
        hide: "Nascondi",
        detail: "Dettaglio",
        priceQuery: "Query Prezzo",
        quantityQuery: "Query Quantità",
        uniqueComponentError: "Errore Componente Unico",
        joinColumn: "Colonna Join",
        nameColumn: "Colonna Nome",
        variantScript: "Script Variante",
        variantImages: "Immagini Variante",
        variantJoinColumn: "Colonna Join Variante",
        showAll: "Mostra Tutto",
        showSelected: "Mostra Selezionati",
        showByCategory: "Mostra per Categoria",
        showInStock: "Mostra Cosa è in Stock",
        notYetAdded: "Non Ancora Aggiunto",
        added: "Aggiunto",
        startingDate: "Data di Inizio",
        endDate: "Data di Fine",
        themeFeatures: "Caratteristiche del Tema",
        menu: "Menu",
        selectMenu: "Seleziona Menu",
        selectSubmenu: "Seleziona Sottomenu",
        selectMenuBrowser: "Seleziona Browser del Menu",
        nameElement: "Elemento Nome",
  
        subject: "Soggetto",
        picture: "Immagine",
        pictureElement: "Elemento Immagine",
        startingDateElement: "Elemento Data di Inizio",
        endDateElement: "Elemento Data di Fine",
        usersCanEdit: "Gli Utenti Possono Modificare",
        thisPropertyCant: "Questa proprietà NON PUÒ essere utilizzata se i dati vengono recuperati da SQL",
        calendarDisplay: "Proprietà Visualizzazione Calendario",
        monthly: "Mensile",
        generalDisplayLunarDays: "Visualizzazione Generale dei Giorni Lunari",
        agenda: "Agenda",
        listofWeeklyEvents: "Elenco Eventi Settimanali",
        weekly: "Settimanale",
        weekend: "Fine Settimana",
        generalDisplayWeekDays: "Visualizzazione Generale dei Giorni Feriali",
        showWeekendDaysWhileWeekdays: "Mostra Giorni del Fine Settimana Mentre Mostra Giorni Feriali",
        daily: "Giornaliero",
        listofDailyEvents: "Elenco Eventi Giornalieri",
        startTime: "Orario di Inizio",
        endTime: "Orario di Fine",
        changeDefaultColor: "Cambia Colori Predefiniti",
        weekdayColor: "Colore Giorni Feriali",
        weekendColor: "Colore Fine Settimana",
        eventColor: "Colore Evento",
        groupBySql: "Raggruppa per SQL",
        groupColumnName: "Nome Colonna di Raggruppamento",
        assignedColumnName: "Nome Colonna Assegnata",
        taskPriortiyColumnName: "Nome Colonna Priorità Attività",
        taskSubjectColumnName: "Nome Colonna Soggetto Attività",
        taskDetailColumnName: "Nome Colonna Dettaglio Attività",
        taskQuery: "Query Attività",
        taskKeyExpression: "Espressione Chiave Attività",
        taskTitleExpression: "Espressione Titolo Attività",
        taskStartDateExpression: "Espressione Data di Inizio Attività",
        taskEndDateName: "Nome Data di Fine Attività",
        taskParentExpression: "Espressione Genitore Attività",
        taskProgressExpression: "Espressione Progresso Attività",
        taskColorExpression: "Espressione Colore Attività",
        taskConnections: "Connessioni Attività",
        diagramClear: "Sei sicuro di voler cancellare il diagramma? Questa azione non può essere annullata.",
        uniqueComponentMenuCloneWarning: "Si prega di modificare il componente unico prima di copiarlo.",
        filesLoading: "Caricamento file in corso...",
        couldnotbeLoaded: "non è stato possibile caricare.",
        fileSizeLimit: "Limite Dimensioni File",
        filesUploaded: "File caricati",
        filesWillDeleted: "I file saranno cancellati!",
        filesSureDelete: "Sei sicuro di voler eliminare i file?",
        fileDelete: "Vuoi eliminare il file?",
        uploadedPhotos: "",
        maxSize: "Dimensione Massima",
        center: "Centro",
        size: "Dimensione",
        canItBeUpdated: "può essere aggiornato dopo l'installazione?",
        uploadedFiles: "File Caricati",
        type: "Tipo",
        showdeleteOldData: "Opzioni Cancellazione Dati Vecchi",
        videoBrowser: "Il tuo browser non supporta il tag video.",
 
deleted: "Eliminato",
theseComponentElementsDeleted: "Questi elementi del componente saranno eliminati.",
invalidTarget: "Destinazione non valida",
ridge: "Linea Diritta",
dotted: "Puntinato",
dashed: "Tratteggiato",
addRowColumn: "Aggiungi Riga/Colonna",
dataPagingShow: "Ottieni Dati come Pagine",
borderType: "Tipo di Bordo",
maxCharacters: "Numero Massimo di Caratteri",
edgeThickness: "Spessore del Bordo",
lineSpacing: "Interlinea",
settingsRow: "Impostazioni Riga/Colonna",
addRow: "Aggiungi Riga",
deleteRow: "Elimina Riga",
rowPermission: "Permessi Riga",
addTabs: "Aggiungi Schede",
deletePanel: "Elimina Pannello",
tabPermission: "Permessi Scheda",
addPanel: "Aggiungi Pannello",
panelPermission: "Permessi Pannello",
backgroundColor: "Colore di Sfondo",
saveAndCloseChildForm: "Salva e Chiudi Questo Modulo Figlio All'Apertura di un Nuovo Modulo",
icon: "Icona",
selectForm: "Seleziona Modulo",
fontColor: "Colore del Carattere",
minCharacters: "Numero Minimo di Caratteri",
generalCategory: "Categoria Generale",
removeRow: "Sei sicuro di voler eliminare il record?",
send: "Invia",
declineFinish: "Rifiuta e Concludi",
finish: "Concludi",
noData: "Nessun Dato",
createEditMenu: "Crea/Modifica Menu",
addNewBreakdown: "Aggiungi Nuovo Scomparto",
addForm: "Aggiungi Modulo",
addBottomBreak: "Aggiungi Interruzione Inferiore",
addNode: "Aggiungi Nodo",
topCategory: "Categoria Superiore",
explanation: "Spiegazione",
browserTitle: "Titolo Browser",
formTitle: "Titolo Modulo",
addSqlColumn: "Aggiungi Colonna SQL",
formBg: "Sfondo Modulo",
codeEditor: "Editor di Codice",
authorityType: "Tipo di Autorizzazione",
viewOnly: "Solo Visualizzazione",
dark: "Scuro",
white: "Bianco",
hightBlack: "Nero",
run: "Esegui",
rowColor: "Colore Riga",
operator: "Operatore",
flowOfForm: "Flusso del Modulo",
columnWidth: "Larghezza Colonna",
alignLeft: "Allinea a Sinistra",
alignRight: "Allinea a Destra",
alignButton: "Allinea Pulsante",
imageTableNameColumn: "Nome Tabella",
uploadBg: "Carica Sfondo",
selectFile: "Seleziona File",
dropFile: "Rilascia il File qui",
alwaysRoundUp: "Arrotonda Sempre per Eccesso",
alwaysRoundBot: "Arrotonda Sempre per Difetto",
default: "Predefinito",
deleteOldDataTable: "Eliminare i vecchi dati della tabella?",
deleteOldData: "Eliminare i dati vecchi?",
integer: "Intero",
double: "Numero Decimale",
pleaseFillFields: "Si prega di compilare i campi obbligatori.",
toastColumnNameNumber: "Il nome della colonna non può iniziare con un numero",
areYouSure: "Sei sicuro?",
columnDeleted: "La colonna sarà eliminata",
allColumnDeleted: "Tutte le colonne saranno eliminate",
idCantStartNumber: "L'ID non può iniziare con un numero",
uploadFile: "Carica File",
fileViewer: "Visualizzatore File",
ok: "Okay",
map: "Mappa",
editPanel: "Modifica Pannello",
filterColumnName: "Nome Colonna Filtro",
hintExamples: "ImportoTotale, esempio:Stipendio",
columnLanguageSettings: "Impostazioni Lingua Colonna",

fileColumn: "Colonna File",
fileNameColumn: "Colonna Nome File",
extensionColumn: "Colonna Estensione",
multipleSelection: "Selezione Multipla",
addSelectionsRows: "Aggiungi selezioni come righe",
mapSettings: "Impostazioni Mappa",
formatAndFeatures: "Formato e Funzionalità",
enterDateRange: "Inserisci il valore dell'intervallo di date",
filesCanUploadedUsers: "Gli utenti possono caricare file",
barcodeColumn: "Colonna Codice a Barre",
autoAscend: "Auto Crescente",
roundType: "Tipo di Arrotondamento",
font: "Font",
columnProcess: "Operazione Colonna",
exportColumnTotal: "Esporta il totale della colonna",
outsideElementDisabled: "Disabilita Elementi Esterni",
chat: "Chat",
languages: "Lingue",
display: "Immagine",
enterYourComment: "Inserisci il tuo commento",
comment: "Commenti",
fileUploaded: "file caricati",
pdfViewIsButton: "Mostra come Pulsante",
takeScreenshot: "Cattura Schermata",
alertText: "Testo di Avviso",
clearButtonVisible: "Mostra Pulsante di Cancellazione",
allText: "Tutto",
letter: "Solo Lettera",
letterandnumber: "Lettera e Numero",
letterandsymbol: "Lettera e Simbolo",
numberandsymbol: "Numero e Simbolo",
showIDColumn: "Mostra Colonna ID",
showCopyButton: "Mostra Pulsante di Copia",
showDeleteButton: "Mostra Pulsante di Eliminazione",
showCheckboxButton: "Mostra Pulsante Checkbox",
autoRowHeight: "Altezza Automatica delle Righe",
exportImport: "Esporta/Importa Modulo",
export: "Esporta",
import: "Importa",
toastImport: "Importazione del Modulo Completata.",
warningApproveImport: "Conferma l'operazione dopo aver apportato le modifiche necessarie. L'importazione del modulo chiuderà il modulo attualmente aperto.",
defaultTodaysDateWithTime: "Il valore predefinito è la data e l'ora attuali",
filterVisible: "Mostra Filtri di Colonna",
updatetableMaxQuantity: "Quantità Massima Aggiornabile",
hideQuantity: "Nascondi selezione quantità",
  
disableQuantityTextbox: "Disabilita la digitazione della quantità",
multiplesOfQuantity: "Incrementa/decrementa multipli della quantità",
justnumber: "Solo numeri",
paymentHourWarning: "Non è possibile effettuare pagamenti al di fuori dell'orario di checkout.",
addColumn: "Aggiungi Colonna",
mailSettings: "Opzioni E-mail",
newTaskMailDetail: "Testo E-mail da Inviare Quando Viene Assegnato un Nuovo Compito",
updateTaskMailDetail: "Testo E-mail da Inviare Quando Viene Modificato un Compito",
taskOnDropMail: "Testo E-mail da Inviare Quando un Compito Viene Spostato",
selectSMTP: "Seleziona Indirizzo SMTP",
taskCreator: "Assegnatario del Compito",
taskEndDate: "Data di Scadenza del Compito",
tags: "Tag",
taskStartDate: "Data di Inizio del Compito",
taskAssigned: "Persona Assegnata al Compito",
taskDetail: "Dettaglio Compito",
taskSubject: "Titolo del Compito",
taskPri: "Priorità del Compito",
taskEdit: "Aggiornamento Compito",
addTask: "Aggiungi Compito",
taskStatus: "Stato del Compito",
selectPriorty: "Seleziona Priorità",
excelExportSettings: "Impostazioni Esportazione Excel",
excelExportRequiredColor: "Colore della Colonna Campo Obbligatorio",
allDataExcelExport: "Esporta Dati come Excel",
warningApproveImportReport: "Conferma l'operazione dopo aver apportato le modifiche necessarie. L'importazione del report chiuderà il report attualmente aperto.",
reportName: "Nome Report",
emptyReportName: "Aggiungi il nome del rapporto dal lato destro.",
old: "Vecchio",
upperNode: "Refrazione Superiore",
pdfViewStaticPdf: "Mostra File Fisso",
actions: "Azioni",
loading: "Caricamento",
exportDataToExcell: "Esporta tutti i dati in Excel",
showRefreshIcon: "Pulsante Aggiorna",
headerFilter: "Filtro Intestazione",
filterRowVisible: "Appare la Casella di Ricerca nella Colonna",
password: "Password",
categories: "Categorie",
pressEnter: "Premi Invio",
historyColumnValue: "Colonna Storico Dati",
hideMobile: "Nascondi su Dispositivi Mobili",
align: "Allineamento",
theme: "Tema",
info: "Informazioni",
showTitle: "Mostra Titolo",
purple: "Viola",
brown: "Marrone",
yellow: "Giallo",
turnOnComments: "Apri Commenti",
showContent: "Mostra Contenuto",
canBeHidden: "Può Essere Nascosto",
allowEdit: "Consenti Modifica",
allowDelete: "Consenti Eliminazione",
allowAdd: "Consenti Aggiunta",
allowCopy: "Consenti la copia",
toastDeleteErrorMessage: "Questo componente è utilizzato negli script delle Preferenze del Modulo. Non può essere eliminato.",
formOpeningMultiElementEvent: "Evento di Apertura Multi-Elemento del Modulo",
resetDatagridLocalSettings: "Reimposta Impostazioni Locali Datagrid",
loginP1: "River fornisce un sistema di memoria globale come memoria aziendale che contiene informazioni come esperienze passate, successi, insuccessi, lezioni apprese, cultura e valori dell'organizzazione o istituzione che possono essere utilizzate come fonte di riferimento per decisioni future e processi, la conoscenza dei propri dipendenti, le esperienze dei clienti, lo sviluppo di prodotti e servizi e molte altre informazioni. Queste informazioni guideranno gli obiettivi e le strategie future dell'azienda.",
loginP2: "River aiuta la tua azienda a gestire i processi aziendali in modo più efficiente ed efficace grazie alla sua struttura supportata da applicazioni web e mobili avanzate. Tutto ciò di cui hai bisogno è accessibile come il tuo computer, tablet o smartphone.",
loginConnect: "Connetti",
loginText: "Accesso",
loginRightMore: "Altro",
loginHeaderP1: "La Tua Memoria Aziendale",
loginHeaderP2: "Mobilità",
loginHeaderP3: "Potenza Grafica",
kanbanaddTasks: "Aggiungi Compito Kanban",
tasksstatusDesciption: "Visualizza il nome della colonna a cui verrà assegnato il compito Kanban.",
taskssubjectDescription: "Consente di inserire il titolo del compito Kanban. Il titolo Kanban è un campo obbligatorio.",
taskassignedToTask: "Gruppi Aggiunti al Compito",
taskassignedTaskDescription: "Consente la selezione dei gruppi da assegnare al compito kanban.",
addedForTasks: "Aggiunto Per il Compito",
taskAssignedInfoDescription: "Persone da Notificare per il Compito",
assignedInfo: "Persone da Notificare",
groupInfo: "Gruppi da Notificare per il Compito",
sortEnd: "Ordina per Data di Fine",
sender: "Da",
forward: "Inoltra",
attachments: "Allegati",
loginP3: "I diagrammi Kanban e Gantt sono strumenti estremamente utili per visualizzare un progetto o un flusso di lavoro. Forniscono una rappresentazione visiva degli elementi di lavoro, del loro stato attuale e del flusso di lavoro nelle diverse fasi del business. Questo rende facile individuare i punti critici, prioritizzare i lavori e migliorare l'efficienza operativa. I diagrammi Kanban e Gantt sono pratici ed efficaci per la gestione di progetti agili, lo sviluppo software e altri processi di miglioramento continuo.",
taskdetailDescription: "Consente di inserire i dettagli del compito Kanban. Il dettaglio Kanban è un campo obbligatorio.",
taskassignedDescription: "Consente agli utenti di essere assegnati al compito Kanban. Almeno un utente deve essere assegnato a Kanban.",
taskstartdateDescription: "Consente di selezionare la data di inizio del compito Kanban. Nel Kanban, la data di inizio prende automaticamente l'orario di creazione del compito.",
taskenddateDescription: "Consente di selezionare la data di fine del compito Kanban. Nel Kanban, la data di fine del compito è automaticamente una settimana dopo l'orario di creazione del compito.",
taskpriDescription: "Consente di selezionare lo stato di priorità del compito Kanban. Nel Kanban, la data di scadenza del compito prende automaticamente una settimana dopo l'orario di creazione del compito.",
tagsDescription: "Consente di aggiungere tag al compito Kanban. I tag vengono separati tra loro con virgole.",
outlookDescription: "Quando l'opzione Aggiungi Compito a Outlook è attivata, un compito viene automaticamente aggiunto al calendario accedendo al tuo account di Outlook.",
taskAssInfoDescription: "Questo è il campo utilizzato per le persone che non sono assegnate al compito ma le cui informazioni sono richieste. Le persone aggiunte per informazione non possono agire sui compiti.",
receiver: "Destinatario",
 
draft: "Bozza",
spam: "Email Non Necessaria",
filterstate: "Filtro per Stato",
checkForspelling: "Verifica Errori di Ortografia",
emailToForward: "Email da Inoltrare",
lowImpMails: "Email a Bassa Importanza",
normalImpMails: "Email a Media Importanza",
highImpEmails: "Email ad Alta Importanza",
showReadMails: "Mostra Email Lette",
groupinfoDescription: "Questo è il campo utilizzato per i gruppi che non sono assegnati a un compito, ma di cui si richiedono informazioni. Le persone appartenenti ai gruppi aggiunti per informazione non possono operare sui compiti.",
timeoutMission: "Tempo per Avviare l'Avviso Prima della Scadenza della Missione",
timeoutmissionDescription: "Usato per specificare il tempo di avvio dell'avviso quando il compito Kanban si avvicina al suo termine.",
taskperiodDescription: "Usato per specificare l'intervallo di tempo del promemoria periodico quando si avvicina la data di fine di un compito kanban.",
taskProgress: "Progresso Compito",
taskProgressDescription: "Viene utilizzato per indicare il progresso di un compito Kanban. Il progresso può essere visualizzato sul diagramma di Gantt.",
updatekanbanTask: "Aggiornamento Compito Kanban",
taskcreatorDescription: "Il Kanban consente di visualizzare il creatore del compito.",
uploadfileDescription: "Usato per caricare file sul compito Kanban. I file o le immagini vengono registrati su DMS.",
infoPersons: "Persone da Notificare",
warningtime: "Tempo di Avviso",
taskPeriod: "Periodo Compito",
taskProgresSatus: "Stato Avanzamento Compito",
makeTask: "Creatore del Compito",
addedforTasks: "Aggiunto Per Compito",
outlookIntegration: "Integrazione Outlook",
loginoutlook: "Accedi al Calendario Outlook",
fetchoutlook: "Ottieni Dati dal Calendario Outlook",
progressStatus: "Stato di Avanzamento",
taskColors: "Colori Compito",
addcalendarTask: "Aggiunta Compito al Calendario",
updatetaskCalendar: "Aggiornamento Compito Calendario",
statusComplete: "Filtro Stato di Completamento",
priorityFilter: "Filtro Priorità",
columnFilter: "Filtro Colonna",
assignPerson: "Persone Assegnate",
assignGroup: "Gruppi Assegnati",
noTask: "Nessun Compito Trovato",
resetSort: "Reimposta Ordinamento",
sortASC: "Ordina A - Z",
sortDESC: "Ordina Z - A",
sortAppointment: "Ordina per Data di Assegnazione",
trash: "Cestino",
showOlderMail: "Mostra Email Più Vecchie",
mailNotFound: "Email Non Trovata",
sendNewMail: "Invia Nuova Email",
showUnreadMails: "Mostra Email Non Lette",
updateColumnMail: "Messaggio da Contattare Quando Aggiungi Compito alla Colonna",
addTaskToOutlook: "Aggiungi Compito a Outlook",
enterYourMessage: "Inserisci il Tuo Messaggio",
reminder: "Promemoria",
determinedToTask: "Tempo specificato per il compito",
remainingTime: "Tempo rimanente",
hours: "Ore",
withoutPriority: "Senza priorità",
lowPri: "Priorità Bassa",
normalPri: "Priorità Media",
highPri: "Priorità Alta",
showAllTasks: "Mostra Tutti",
showIncompleteTasks: "Mostra Incompleti",
showCompeletedTasks: "Mostra Completati",
reportDesigner: "Progettazione Report",
fileManagementSystem: "Sistema di Gestione dei File",
formDesigner: "Progettazione Modulo",
boardDesigner: "Progettazione Bacheca",
themeSettings: "Impostazioni Tema",
social: "Sociale",
profile: "Profilo",
contacts: "Contatti",
files: "File",
pages: "Pagine",
noResultsFound: "Nessun Risultato Trovato",
processes: "Processi",
filterByStatus: "Filtra per Stato",
filterByPri: "Filtra per Priorità",
filterByCol: "Filtra per Colonna",
todoAddTasks: "Aggiungi Compiti a To-Do",
addLabel: "Inserisci Etichetta",
showWithSubTasks: "Mostra Sottocompiti",
riverBoard: "Bacheche di River",
notify: "Notifiche",
likedYourPost: "Ha Apprezzato il Tuo Post",
likedYourComment: "Ha Apprezzato il Tuo Commento",
systemNotify: "Notifiche di Sistema",
readAllNotify: "Leggi Tutte le Notifiche",
recommendedUsers: "Persone Consigliate",
suggestedPosts: "Post Suggeriti",
sPostReposted: "Post Ripubblicato",
shareSomething: "Condividi Qualcosa",
updatePost: "Aggiorna Post",
ifYouDoNotSelectGroup: "Se non selezioni un gruppo, chiunque può vedere il tuo post",
addComment: "Aggiungi Commento",
sendComment: "Invia Commento",
areYouSureYouWantToDeleteThisPost: "Sei sicuro di voler eliminare il post",
yes: "Sì",
no: "No",
deletePost: "Elimina Post",
postSuccesfullyDeleted: "Post eliminato con successo",
deleteComment: "Elimina Commento",
areYouSureYouWantToDeleteThisComment: "Sei sicuro di voler eliminare questo commento",
commentSuccesfullyDeleted: "Commento eliminato con successo",
sharePost: "Condividi Post",
areYouSureYouWantToShareThisPost: "Sei sicuro di voler condividere questo post",
repostedSuccessfully: "Ripubblicato con successo",
upload: "Carica",
refresh: "Aggiorna",
allowedExtentions: "Estensioni consentite: JPG, GIF o PNG. Dimensione massima del file",
systemLandingPagePreference: "Preferenza Pagina Iniziale di Sistema",
allownonadminuserstosearchforotherusers: "Consenti agli utenti non amministratori di cercare altri utenti",
passwordChange: "Cambio Password",
newPassword: "Nuova Password",
retypethenewpassword: "Ripeti la nuova password",
notifications: "Notifiche",
showWhoLikesYourPost: "Mostra chi apprezza i tuoi post",
showWhoLikesYourComment: "Mostra chi apprezza i tuoi commenti",
aboutMe: "Su di Me",
bio: "Biografia",
dateOfBirth: "Data di Nascita",
website: "Sito Web",
phone: "Telefono",
saveChanges: "Salva Modifiche",
username: "Nome Utente",
content: "Contenuto",
projectManagement: "Gestione Progetti",
addPage: "Aggiungi Pagina",
addFolder: "Aggiungi Cartella",
showUsers: "Utenti che Possono Vedere",
showGroups: "Gruppi che Possono Vedere",
readonlyUsers: "Utenti che Possono Leggere",
readonlyGroups: "Gruppi che Possono Leggere",
columnHeight: "Altezza Colonna",
showKanbanCount: "Mostra Informazioni Quantità Kanban",
kanbanSettings: "Impostazioni Kanban",
gantSettings: "Impostazioni Gantt",
schedulerSettings: "Impostazioni Calendario",
showRowLines: "Mostra Linee Righe",
activateOutlookConnection: "Attiva Connessione Outlook",
selectOutlookColumn: "Seleziona la Colonna di Outlook",
selectSMTPopoverText: "Viene selezionato il server SMTP di posta da utilizzare nei componenti Kanban, Gantt, Calendario o To-Do.",
menuFilePathId: "ID Cartella Menu River",
recycleBinPathName: "Nome Cartella Cestino River",
recycleBinPathId: "ID Cartella Cestino River",
fileNameChangedMessage: "Il nome del file è stato cambiato con successo.",
fileNameChangedErrorMessage: "Impossibile cambiare il nome del file.",
fileCreatedMessage: "Il file è stato creato con successo.",
newTaskMailDetailPopoverText: "Quando viene assegnato un nuovo compito ai componenti Kanban, Gantt, Calendario o To-Do, questa è la parte del contenuto dell'email che verrà aggiunta alla notifica automatica inviata agli utenti assegnati.",
taskOnDropMailPopoverText: "Questa è la parte del contenuto dell'email che verrà aggiunta alla notifica automatica inviata ai componenti Kanban, Gantt, Calendario o To-Do quando il compito viene spostato in una colonna diversa.",
createdBy: "Creato da",
updatedBy: "Aggiornato da",
createdDate: "Data Creazione",
updatedDate: "Data Aggiornamento",
folderPermissionSettings: "Impostazioni Autorizzazione Cartella",
quantity: "Quantità",
fileManagementPermissionSettings: "Impostazioni Autorizzazione Gestione File",
fileNotFoundErrorText: "Nessun file trovato corrispondente ai criteri di ricerca.",
updateTaskMailDetailPopoverText: "Questa è la parte del contenuto dell'email che verrà aggiunta alla notifica automatica inviata agli utenti assegnati quando vengono apportate modifiche ai componenti Kanban, Gantt, Calendario o To-Do nel compito corrispondente.",
selectOutlookColumnPopoverText: "Viene selezionata la colonna in cui includere i compiti da aggiungere tramite Outlook.",
createFolder: "Crea Cartella",
versionHistory: "Cronologia Versioni",
deletePermanently: "Elimina Definitivamente",
recover: "Recupera",
groupPermissions: "Autorizzazioni Gruppo",
allowUpload: "Consenti Caricamento File",
allowView: "Consenti Visualizzazione File",
allowDownload: "Consenti Download File",
permissionType: "Tipo di Autorizzazione",
applyToSubFolders: "Applica a tutte le sottocartelle",
newFolderName: "Nuovo Nome File",
tagTips: "I tag devono essere preceduti dal simbolo #. Possono contenere solo lettere e numeri.",
addAsNewVersion: "Aggiungi come Nuova Versione",
addAndChangeName: "Carica con Cambio Nome",
skip: "Salta",
fileExistError: "Esiste già un file con lo stesso nome nella cartella di destinazione",
processFileShow: "Mostra Cartella Processi River",
processFilePathName: "Nome Cartella Processi River",
processFilePathId: "ID Cartella Processi River",
menuFileShow: "Mostra Cartella Menu River",
menuFilePathName: "Nome Cartella Menu River",
fileCreatedErrorMessage: "Creazione file non riuscita.",
fileDeletedessage: "Eliminazione file completata.",
fileDeletedErrorMessage: "Eliminazione file non riuscita.",
fileMovedMessage: "Spostamento file completato.",
fileMovedErrorMessage: "Spostamento file non riuscito.",
fileCopyMessage: "Copia file completata.",
fileCopyErrorMessage: "Copia file non riuscita.",
fileDownloadMessage: "Download file completato.",
fileDownloadErrorMessage: "Download file non riuscito.",
fileSizeShowErrorMessage: "Non è possibile visualizzare il file perché le dimensioni sono eccessive. Si prega di scaricare il file.",
unsupportedFile: "File non supportato",
fileNameError: "Esiste già un file con questo nome, prova con un nome diverso",
fileGivePermissionMessage: "Autorizzazione file completata con successo.",
fileGivePermissionErrorMessage: "Errore nell'autorizzazione file. Registrazione non riuscita.",
groupName: "Nome Gruppo",
createGroup: "Crea Gruppo",
conversation: "Conversazioni",
startConversation: "Inizia Conversazione",
userNotFound: "Contatto non trovato",
chatNotFound: "Chat non trovata",
themeTypeLight: "Chiaro",
themeTypeBorderedLight: "Chiaro con Bordo",
themeHalfDark: "Mezzo Scuro",
themeDark: "Scuro",
themeRouteAnimation: "Animazione di Transizione",
themeRouteAnimationFadeInLeft: "Fade a Sinistra",
themeRouteAnimationZoomIn: "Zoom",
themeRouteAnimationFadeIn: "Fade",
themeRouteAnimationNone: "Nessuna",
themeMenuType: "Tipo di Menu",
themeMenuTypeHorizontal: "Orizzontale",
themeMenuTypeVertical: "Verticale",
themeMenuCollapse: "Menu Ridotto",
themeNavbarColor: "Colore Barra di Navigazione",
themeNavbarType: "Tipo di Barra di Navigazione",
themeSticky: "Fisso",
themeStatic: "Statico",
themeFloating: "Fluttuante",
themeHidden: "Nascosto",
themeFooterType: "Tipo di Piè di Pagina",
catalog: "Catalogo",
riverBoards: "Bacheche River",
clipboardDesigner: "Progettazione Appunti",
designMode: "Sei in Modalità di Progettazione",
editPage: "Modifica Pagina",
rename: "Rinomina",
exportToPdf: "Esporta in PDF",
exportToExcel: "Esporta in Excel",
revenueReport: "Rapporto Ricavi",
header: "Titolo",
connection: "Connessione",
primaryKey: "Chiave Primaria",
secondaryKey: "Chiave Secondaria",
completedKey: "Chiave Completata",
countKey: "Chiave Conteggio",
statusKey: "Chiave Stato",
progressConnection: "Connessione Progresso",
progressScript: "Script Progresso",
progressPrimaryKey: "Chiave Primaria Progresso",
progressCountKey: "Chiave Conteggio Progresso",
progressPercentageKey: "Chiave Percentuale Progresso",
progressColorKey: "Chiave Colore Progresso",
keyColumn: "Colonna Chiave",
currency: "Valuta",
whitePage: "Pagina Bianca",
noReportSelected: "Nessun Report Selezionato",
noTypeSelected: "Nessun Tipo di Report Selezionato",
whitePageReports: "Report Pagina Bianca",
addBranch: "Aggiungi Sottoinsieme/Sottosezione",
branchName: "Nome Sottoinsieme",
rowNumber: "Numero di Sequenza",
reportTypeSelect: "Seleziona Tipo di Report",
reportDescription: "Descrizione del Report...",
useWeb: "Usa sul Web",
useMobile: "Usa su Mobile",
mainScript: "Script Principale",
prerunScript: "Eseguire lo script pre?",
preScript: "Script Pre",
testIt: "Prova",
selectAll: "Seleziona Tutto",
addReport: "Aggiungi Report",
selectReportType: "Seleziona Tipo di Report",
reportType: "Tipo di Report",
reportDesingPermission: "L'utente non ha le autorizzazioni per progettare report.",
reportImportedOpenLeftMenu: "Report importato. Puoi aprirlo dal menu di sinistra.",
forReportExport: "Per esportare il report, devi prima selezionare un report.",
anErrorOccurred: "Si è verificato un errore",
reportDeleted: "Report Eliminato",
branchDeleted: "Sottoinsieme Eliminato",
addBranchName: "Inserisci il nome del sottoinsieme",
addedBranch: "Sottoinsieme Aggiunto",
selectConnectionAddress: "Seleziona un indirizzo di connessione",
enterSqlScript: "Inserisci lo script SQL",
enterReportName: "Inserisci il nome del report",
reportAdded: "Report Aggiunto",
numberOfDataToBeProcessed: "Connessione riuscita. Numero di dati da elaborare:",
dataNotFoundCheckYourConnection: "Dati non trovati! Controlla la connessione e il comando SQL",
connectionNotFoundCheckYourConnection: "Connessione non riuscita! Controlla la connessione e il comando SQL",
fillConnectionAndSql: "Compila la connessione e il comando SQL",
allTypesActivate: "Tutti i tipi attivati al momento della visualizzazione",
passwordRequired: "Password richiesta",
showLess: "Mostra Meno",
showMore: "Mostra Di Più",
authority: "Autorità",
preScriptDescription: "Descrizione del PreScript",
enterReportFilters: "Inserisci Filtri del Report",
gridDesigner: "Progettazione Griglia",
addMainBranch: "Aggiungi Sottoinsieme Principale",
resetSettings: "Ripristina Impostazioni",
columsToGroup: "Usa il menu contestuale delle colonne dell'intestazione per raggruppare i dati",
customize: "Personalizza",
colorType: "Tipo di Colore",
formatManagement: "Gestione Formato",
bottomTotalColumn: "Colonna Totale Inferiore",
formatType: "Tipo di Formato",
datagridSettingsReset: "Le impostazioni della griglia dati saranno ripristinate",
unexpectedError: "Spiacenti, si è verificato un errore imprevisto.",
changesSaved: "Le modifiche sono state salvate",
addGantTask: "Aggiungi Attività Gantt",
updGantTask: "Aggiorna Attività Gantt",
updSchedulerTask: "Aggiorna Attività Calendario",
updTodoTask: "Aggiorna Attività da Fare",
completeTask: "Completa Attività",
cantBeEmptyKanban: "Il titolo dell'attività, i dettagli dell'attività e l'assegnatario dell'attività non possono essere vuoti",
successAdd: "L'attività è stata aggiunta con successo",
successUpd: "L'attività è stata aggiornata con successo",
statusChangeTodo: "Lo stato di completamento dell'attività è stato modificato con successo.",
reminderSended: "Promemoria dell'attività inviato con successo",
askForCompleteTask: "Sei sicuro di voler completare l'attività?",
infoForCompletedTask: "Anche le sottotattività dell'attività verranno completate.",
noPermisison: "Non hai l'autorizzazione.",
successDependencyAdded: "Dipendenza dell'attività aggiunta con successo",
filterimportant: "Filtra per Importanza",
enterEmail: "Inserisci l'indirizzo email",
reply: "Rispondi",
newCol: "Nuova Colonna",
thickness: "Spessore",
distanceToUpperComponent: "Distanza dal componente superiore",
companyName: "Nome dell'azienda",
showEmailModule: "Mostra modulo email",
distanceToBottomComponent: "Distanza dal componente inferiore",
canNonAdminUsersPost: "Gli utenti non amministratori possono pubblicare",
columnColor: "Colore Colonna",
byWho: "di",
updated: "Aggiornato",
commentAdded: "Commento Aggiunto",
completed: "Completato",
addRemoveColumns: "Aggiungi/Rimuovi Colonne",
searchMessage: "Cerca messaggi",
riverReports: "River BI Reports",
forms: "Moduli",
searchinforms: "Cerca nei Moduli",
searchVoiceMessage: "Il messaggio che stai cercando è in questo file audio",
searchVoiceMessages: "Ricerca messaggi vocali",
showSocialNotifications: "Mostra Notifiche Social",
showProjectManagementNotifications: "Mostra Notifiche di Gestione Progetto",
showProcessNotifications: "Mostra Notifiche di Processo",
pageDesingPermission: "L'utente non ha le autorizzazioni per progettare pagine.",
filemanagementDesingPermission: "L'utente non ha le autorizzazioni per la gestione dei file.",
taskDelete: "L'attività verrà eliminata.",
tasksWillDeleted: "Sei sicuro di voler eliminare l'attività?",
componentSettings: "Impostazioni Componente",
aboutComponent: "Informazioni sul Componente",
sureSaveChanges: "Sei sicuro di voler salvare le modifiche?",
showDeletedNotifications: "Mostra le Notifiche Eliminate",
resetPageLayout: "Ripristina Layout Pagina",
downloadingSS: "Download dello screenshot in corso. Attendere.",
showHorizontalLines: "Mostra Linee Orizzontali",
turnOnVerticalHide: "Attiva Nascondi Verticale",
        whichHorizontalLinelinesAreNotVisible: "Quali linee orizzontali - non mostrare le linee",
        priority: "Priorità",
        boardSidebarMessageField: "Campo Messaggio Attività",
        boardSidebarExtraField: "Campo Componente Aggiuntivo Attività",
        boardSidebarTaskHistory: "Cronologia Attività",
        loadMoreNotifications: "Mostra Altre Notifiche",
        deletedNotifications: "Notifiche Eliminate",
        noFormNotification: "Nessuna Notifica di Processo Ancora",
        noDeletedFormNotifications: "Nessuna Notifica di Processo Eliminata",
        noDeletedBoardNotification: "Nessuna Notifica di Bacheca Eliminata",
        noBoardNotification: "Nessuna Notifica di Bacheca Ancora",
        noDeletedSocialNotification: "Nessuna Notifica di Social Media Eliminata",
        noSocialNotification: "Nessuna Notifica di Social Media Ancora",
        deleteAll: "Elimina Tutto",
        markAllRead: "Segna Tutto Come Letto", board: "Tavola",
        formNotifications: "Notifiche del Modulo",
        boardNotifications: "Notifiche della Bacheca",
        socialNotifications: "Notifiche Sociali",
        form: "Modulo",
        email: "E-mail",
        oldFormName: "Nome del Vecchio Modulo",
        newFormName: "Nome del Nuovo Modulo",
        oldFormVariable: "Variabile del Vecchio Modulo",
        newFormVariable: "Variabile del Nuovo Modulo",
        oldCategory: "Vecchia Categoria",
        newCategory: "Nuova Categoria",
        connectionInFile: "Connessione nel File",
        yourConnection: "La Tua Connessione",
        element: "Elemento",
        script: "Script",
        formClosingEvent: "Evento di Chiusura del Modulo",
        message: "Messaggio",
        formExportTextError: "Non sono stati aggiunti componenti al modulo o il modulo non è stato salvato. Per evitare che l'operazione fallisca, correggere questo problema.",
        checkRequiedFields: "Verifica Campi Obbligatori",
        dontCheckRequiredFields: "Non Verificare Campi Obbligatori",
        createPdfWarning: "Se selezionato, il modulo non verrà salvato e chiuso",
        cratePdf: "Crea PDF",
        identityNumber: "Numero di Identità",
        minPaymentHour: "Ora Min",
        maxPaymentHour: "Ora Max",
        darkBlue: "Blu Scuro",
        orange: "Arancione",
        component: "Componente",
        properties: "Proprietà",
        dmsPath: "Percorso DMS",
        dashboard: "Dashboard",
        slider: "Slider",
        list: "Elenco",
        otherImages: "Altre Immagini",
        unitScript: "Script dell'Unità",
        updatableMaxQuantity: "Quantità Massima Aggiornabile",
        newPage: "Nuova Pagina",
        code: "Codice",
        resetHiddenComponent: "Ripristina il Valore dei Componenti Nascosti di Destinazione",
        bold: "Grassetto",
        italic: "Corsivo",
        stringFormat: "Formato Stringa",
        videoUploaded: "Video Caricato",
        fillInAllFields: "Compila Tutti i Campi",
        normalPaging: "Paginazione Normale",
        pageSize: "Dimensione Pagina",
        special: "Speciale",
        link: "Link",
        fontWeight: "Peso del Carattere",
        pdfFileNotFound: "File PDF Non Trovato",
        themeMenuHidden: "Menu Nascosto",
        likedOn: "mi è piaciuto",
        addedTask: "compito aggiunto",
        updatedTask: "attività denominata aggiornata",
        messageAddedToTask: "Messaggio aggiunto all'attività",
        deleteTask: "l'attività denominata è stata eliminata",
        completedTask: "compito completato",
        subCategory: "Sottocategoria",
        toTheBoard: "al consiglio",
        hideTaskDetail: "Nascondi Dettagli Attività",
        selectData: "Seleziona dati",
        noContentYet: "Ancora Nessun Contenuto",
        addRowBetween: "Aggiungi Riga Tra",
        userPermissions: "Permessi utente",
        riverModules: "RIVER Moduli",
        processHistory: "storia del processo",
        commentHasBeenMadeAtThisStage:"Il commento è stato aggiunto in questa fase",
        askForPassword: "Chiedi la password?",
        passwordIsWrong: "La password è errata, riprova per favore.",
        pleaseEnterPassword: "Per favore, inserisci la tua password per continuare.",
        onePaymentEachSave: "Un pagamento per ogni salvataggio",
        clearSelection: "Cancella Selezione",
        allowDownloadVersionHistoryDocuments: "Consenti il Download dei Documenti della Storia delle Versioni",

        delegated: "delegato",
        openDocxToPdf: "Apri File come PDF",
        activationStartDate: "Data di Inizio Attivazione",
        activationEndDate: "Data di Fine Attivazione",
        changeActivationDate: "Cambia Data di Attivazione",
        showArchive: "Mostra Archivio",
        hideArchive: "Nascondi Archivio",
        tasks: "Compiti",
        maxFileCountLimit: "Limite massimo di file",
        fileCountLimit: "Limite del numero di file",
        numberOfFilesExceeded: "Numero di file superato",
        numberOfFilesLimit: "Limite del numero di file",
        lineIntermediateAllowAdd: "Aggiungi linea intermedia",
        lineIntermediateAllowCopy: "Copia linea intermedia",
        triggerReport: "Segnalazione di attivazione",
        setWhitePageReportExportName: "imposta il nome di esportazione del report della pagina bianca",
        menuRowUpdated: "Ci sono record con valore auto-incrementato. Il numero auto-incrementato è stato aggiornato: ",
        isMenuRowControl: "Controllo incremento automatico",
        sqlScriptWarning: "L'uso di questo campo può causare perdita di prestazioni.",

        deletedTask: "Compito Eliminato",
        getDeletedTasks: "Ottieni Compiti Eliminati",
        getTasks: "Ottieni Compiti", movePageDesignersNode: "Il pannello/il menu selezionato verrà spostato nella cartella",
        informationAboutMoveToDirectory: "Le autorizzazioni dell'utente del pannello/menu spostato cambieranno in base alle autorizzazioni della cartella in cui viene spostato.",
        move: "Sposta",
        download: "Scarica",
        create: "Crea",
        statistic: "Statistiche",
        priRate: "Tasso di assegnazione per priorità",
        taskcreatorlist: "Elenco dei creatori di attività",
        completionRate: "Tasso di completamento",
        columnsTaskCount: "Numero di attività nelle colonne",
        showKanbanFilter: "Mostra filtri Kanban",
        processToStart: "Processo da avviare",
        fieldMatching: "Corrispondenza dei campi",
        showFileHistory: "Mostra cronologia file",
        dontLetDeleteWhatTheyAdded: "Non permettere di eliminare ciò che hanno aggiunto",
        myJobs: "I miei lavori",
        excelExportName: "nome di esportazione Excel"

    }
  
  }