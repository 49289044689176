import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { WebMessageService } from '@shared/services/api/webmessage.service';
import { id } from '@swimlane/ngx-datatable';
import { EventEmitterService } from '../common';
import { value } from 'devexpress-dashboard/model/index.metadata';

@Injectable()
export class ChatService {
    public contacts: any[];
    public chats: any[];
    public userProfile;
    public isChatOpen: Boolean;
    public isChatCreateGroup: Boolean;
    public chatGroups: any[];
    public selectedChatGroup;
    public selectedSearchText;


    public onContactsChange: BehaviorSubject<any>;
    public onChatsChange: BehaviorSubject<any>;
    public onSelectedChatGroupChange: BehaviorSubject<any>;
    public onChatGroupsChange: BehaviorSubject<any>;
    public onChatOpenChange: BehaviorSubject<Boolean>;
    public onUserProfileChange: BehaviorSubject<any>;
    public onChatCreateGroupChange: BehaviorSubject<Boolean>;
    public onSearchTextSelectedChange: BehaviorSubject<number>;
    groupDefaultImage: any;
    invokeChatMessageReceivedEvent: Subscription;

    constructor(private _httpClient: HttpClient, private _appInfoService: AppInfoService, private _webMessageService: WebMessageService, public _eventEmitterService: EventEmitterService) {

        this.userProfile = this._appInfoService.RiverEntity.currentUser;
        this.isChatOpen = false;
        this.isChatCreateGroup = false;
        this.onContactsChange = new BehaviorSubject([]);
        this.onChatsChange = new BehaviorSubject([]);
        this.onSelectedChatGroupChange = new BehaviorSubject([]);
        this.onChatGroupsChange = new BehaviorSubject([]);
        this.onChatOpenChange = new BehaviorSubject(false);
        this.onUserProfileChange = new BehaviorSubject([]);
        this.onChatCreateGroupChange = new BehaviorSubject(false);
        this.onSearchTextSelectedChange = new BehaviorSubject(0);

        this.invokeChatMessageReceivedEvent = _webMessageService.messageReceived.subscribe((message: any) => {

            if (typeof message.groupUserIds != "undefined") {
                if (message.groupUserIds.split(",").indexOf(this.userProfile.id.toString()) > -1) {

                    if (message.userId != this.userProfile.id.toString()) {
                        this.playAudio();
                    }

                    this.getChatGroups();
                    this.getActiveChatMessages();

                }
            }
            
        })
        let _thisPage = this;
        let promise = this.getBase64Image("/assets/img/rivergroup.png");
        promise.then(function (dataURL) {
            _thisPage.groupDefaultImage = dataURL
        });
        this.resolve().then(val => { })
    }

    playAudio() {
        let audio = new Audio();
        audio.src = "/assets/others/chat_notification.mp3";
        audio.loop = false;
        audio.load();
        audio.play();
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(/*route: ActivatedRouteSnapshot, state: RouterStateSnapshot*/): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getContacts(),
                this.getChatGroups(),
                //this.getChats(),
                //this.getUserProfile(),
                //this.getActiveChats(),
                //this.getChatUsers()
            ]).then(() => {
                resolve();
            }, reject);
        });
    }

    /**
     * Get Contacts
     */
    getContacts(): Promise<any[]> {
        const url = this._appInfoService.appUrl + `api/chat/contacts?userId=` + this.userProfile.id;

        return new Promise((resolve, reject) => {
            this._httpClient.get(url).subscribe((response: any) => {
                response.forEach(el => {
                    if (el.photo != null) {
                        el.photo = "data:image/png;base64," + el.photo
                    }
                })
                this.contacts = response;
                this.onContactsChange.next(this.contacts);
                resolve(this.contacts);
            }, reject);
        });
    }

    /**
     * Get Chat Groups
     */
    getChatGroups() {
        const url = this._appInfoService.appUrl + `api/chat/chat-groups?userId=` + this.userProfile.id;

        return new Promise((resolve, reject) => {
            this._httpClient.get(url).subscribe((response: any) => {
                this.chatGroups = response;

                this.chatGroups.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB.getTime() - dateA.getTime();
                });
                this.chatGroups = response.map(chatGroup => {
                    const date = new Date(chatGroup.date);
                    const hour = date.getHours().toString().padStart(2, '0');
                    const minute = date.getMinutes().toString().padStart(2, '0');
                    const formattedTime = `${hour}:${minute}`;

                    chatGroup.date = formattedTime;
                    return chatGroup;
                });

                this.onChatGroupsChange.next(this.chatGroups);

                resolve(this.chatGroups);
            }, reject);
        });
    }

    /**
    * Get Active Chat Messages
    */
    getActiveChatMessages(): Promise<any[]> {

        const url = this._appInfoService.appUrl + `api/chat/messages?receiverId=` + this.selectedChatGroup.id + "&page=" + 0 + "&userId=" + this.userProfile.id;

        return new Promise((resolve, reject) => {
            this._httpClient.get(url).subscribe((response: any) => {

                this.chats = response;                
                this.chats.forEach((chat: any) => {
                    const foundMessageUser = this._appInfoService.RiverEntity.users.find(user => user.id == chat.sender_id);
                    chat.sender_name = foundMessageUser.fullName;
                    if (chat.file_name) {
                        const file_type = chat.file_name.replace(/^.*\.(.*)$/, "$1");
                        chat.file_type = file_type;
                        this.chatMessageFileDownload(chat.file_id).subscribe(sub => {
                            chat.file_data = sub;
                            chat.file_dataAudio = "data:" + chat.file_data.type + ";base64," + chat.file_data.fileData;
                        })
                    }
                });
                this.onChatsChange.next(this.chats);
                resolve(this.chats);
            }, reject);
        });
    }

    /**
     * Get User Profile
     */
    getUserProfile(): Promise<any[]> {
        const url = `api/chat-profileUser`;

        return new Promise((resolve, reject) => {
            this._httpClient.get(url).subscribe((response: any) => {
                this.userProfile = response;
                this.onUserProfileChange.next(this.userProfile);
                resolve(this.userProfile);
            }, reject);
        });
    }

    /**
     * Get Active Chats
     */
    getActiveChats() {
        const chatArr = this.chats.filter(chat => {
            return this.contacts.some(contact => {
                return contact.id === chat.userId;
            });
        });
    }

    /**
     * Selected Chats
     *
     * @param id
     * receiverId
     */
    selectedChats(chatGroup) {
        this.isSeenMessage(chatGroup.id).subscribe((isSeen: boolean) => {
            this._eventEmitterService.isSeenMessage(isSeen);
        })
        this.selectedChatGroup = chatGroup;
        this.onSelectedChatGroupChange.next(this.selectedChatGroup);
        this._eventEmitterService.invokescrollChatMessageEvent.subscribe(id => {
            this.selectedSearchText = id.id;

            this.onSearchTextSelectedChange.next(this.selectedSearchText);
        })
        this.getActiveChatMessages().then(val => { });

    }

    /**
     * Create New Chat
     *
     * @param id
     * @param chat
     */
    createNewChat(chatGroupModel) {

        let chatGroupAdd = {
            CreatedUserId: this.userProfile.id,
            Name: chatGroupModel.groupName,
            Users: chatGroupModel.groupUsers.toString() + "," + this.userProfile.id,
            File: chatGroupModel.groupPhoto && chatGroupModel.groupPhoto != "" ? chatGroupModel.groupPhoto : this.groupDefaultImage,
            Type: 1,
        };
        if (chatGroupModel.groupUsers.length > 1) {
            chatGroupAdd.Type = 2;
        }
        this.chatGroupAddService(chatGroupAdd).subscribe(data => {
            this.isChatCreateGroup = false;
            this.onChatCreateGroupChange.next(this.isChatCreateGroup);
            this.getChatGroups()
        })
    }

    /**
     * Open Chat
     *
     * @param id
     */
    openChat(chatGroup) {
        this.isChatOpen = true;
        this.onChatOpenChange.next(this.isChatOpen);
        this.isChatCreateGroup = false;
        this.onChatCreateGroupChange.next(this.isChatCreateGroup);
        this.selectedChatGroup = chatGroup
        this.selectedChats(chatGroup)
        this._eventEmitterService.chatName(chatGroup.name);
        this._eventEmitterService.setSelectedChat(chatGroup.id);
    }

    /**
     * Open CreateGroup
     *
     * @param id
     */
    openCreateGroup() {
        if (this.isChatCreateGroup == false) {
            this.isChatCreateGroup = true;
            this.onChatCreateGroupChange.next(this.isChatCreateGroup);
        }
        else {
            this.isChatCreateGroup = false;
            this.onChatCreateGroupChange.next(this.isChatCreateGroup);
        }
    }

    /**
     * Open Chat
     *
     * @param id
     */
    openContact(chatContact) {
        
        var chats = this.chatGroups.filter(f => f.userIds.split(',').length == 2 && f.userIds.split(',').includes(chatContact.id.toString()) && f.userIds.split(',').includes(this.userProfile.id.toString()))
        if (chats.length > 0) {
            this.openChat(chats[0])
        } else {
            let chatGroupAdd = {
                CreatedUserId: this.userProfile.id ,
                Name: chatContact.name + "," + this.userProfile.fullName,
                Users: chatContact.id + "," + this.userProfile.id,
                File: "",
                Type: 1
            };

            this.chatGroupAddService(chatGroupAdd).subscribe(data => {
                this.getChatGroups()
            })
        }
        
    }

    /**
     * Update Chat
     *
     * @param chats
     */
    //updateChat(chats) {
    //    return new Promise<void>((resolve, reject) => {
    //        this._httpClient.post('api/chat-chats/' + chats.id, { ...chats }).subscribe(() => {
    //            this.getChatGroups();
    //            resolve();
    //        }, reject);
    //    });
    //}

    /**
     * Update User Profile
     *
     * @param userProfileRef
     */
    updateUserProfile(userProfileRef) {
        this.userProfile = userProfileRef;
        this.onUserProfileChange.next(this.userProfile);
    }

    sendChatMessage(_chatMessage, _chatMessageFileData) {

        let chatMessage = {
            message: _chatMessage,
            date: new Date(),
            userId: this.userProfile.id,
            fileId: 0,
            groupUserIds: "",
            receiverId: this.selectedChatGroup.id,
            userType: 0
        };
        this.isSeenMessage(chatMessage.receiverId).subscribe((isSeen: boolean) => {            
            this._eventEmitterService.isSeenMessage(isSeen);
        });
        return new Promise<any>((resolve, reject) => {
            if (_chatMessageFileData == null) {
                this.chatMessageSendApi(chatMessage).subscribe(result => {
                    resolve(result);
                });
            } else {
                this.getDocument(_chatMessageFileData).then(document => {
                    let fileData = document;
                    let fileName = _chatMessageFileData.name;
                    let fileExtension = _chatMessageFileData.name.split(".").pop();
                    let fileType = _chatMessageFileData.type;
                    let fileIsImage = this.isImage(fileExtension);
                    let fileSize = _chatMessageFileData.size;
                    let chatMessageFile = {
                        fileData: fileData,
                        fileName: fileName,
                        fileExtension: fileExtension,
                        fileType: fileType,
                        fileIsImage: fileIsImage,
                        fileSize: fileSize
                    };
                    this.chatMessageFileSendApi(chatMessageFile).subscribe((result: any) => {
                        chatMessage.fileId = result;
                        this.chatMessageSendApi(chatMessage).subscribe(result => {
                            resolve(result);
                        });
                    });
                });
            }
        });        
    }

    chatMessageSendApi(chatMessage): Observable<string> {
        return this._httpClient.post<string>(this._appInfoService.appUrl + "api/generic/sendmessage", JSON.stringify(chatMessage), this._appInfoService.httpOptions)
    }
    searchChatMessage(searchText: string, userId: number) {
        return this._httpClient.get(`${this._appInfoService.appUrl}api/chat/searchchatmessage?searchtext=${searchText}&userid=${userId}`, this._appInfoService.httpOptions);
    }
    chatMessageFileSendApi(chatMessageFile): Observable<string> {
        return this._httpClient.post<string>(this._appInfoService.appUrl + "api/generic/sendmessagefile", JSON.stringify(chatMessageFile), this._appInfoService.httpOptions)
    }

    chatMessageFileDownload(_id): Observable<string> {
        return this._httpClient.get<any>(this._appInfoService.appUrl + "api/generic/chatmessagefiledownload?id=" + _id);
    }

    chatGroupAddService(chatGroupAdd): Observable<string> {
        return this._httpClient.post<string>(this._appInfoService.appUrl + "api/generic/chatgroupadd", JSON.stringify(chatGroupAdd), this._appInfoService.httpOptions)
    }
    isSeenMessage(chatmessageId: number) {
        return this._httpClient.get(`${this._appInfoService.appUrl}api/chat/seenchatmessage?chatmessageId=${chatmessageId}`, this._appInfoService.httpOptions);
    }
    getDocument(file) {
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (args: any) => {
                if (reader.result) {
                    let base64BlobData: any = args.target.result.split(',').pop();
                    resolve(base64BlobData);
                }
            }

        });
    }

    isImage(extension) {
        let ext = extension.toLowerCase();
        if (ext == "png"
            || ext == "jpg"
            || ext == "jpeg"
            || ext == "jfif"
            || ext == "gif"
            || ext == "tif"
            || ext == "tiff") return true;
        else return false;
    }
    getBase64Image(url) {
        let promise = new Promise(function (resolve, reject) {

            var img = new Image();
            // To prevent: "Uncaught SecurityError: Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported."
            img.crossOrigin = "Anonymous";
            img.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
                resolve(dataURL.replace(/^data:image\/(png|jpg|jpeg|pdf);base64,/, ""));
            };
            img.src = url;
        });

        return promise;
    }
    
}
